import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-easy-crop';
import { RiCropLine } from "react-icons/ri";
import { GrPowerReset } from "react-icons/gr";

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
  });

const getCroppedImg = async (imageSrc, pixelCrop) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        reject(new Error('Canvas is empty'));
        return;
      }
      blob.name = 'croppedImage.jpeg';
      const croppedImageUrl = URL.createObjectURL(blob);
      resolve(croppedImageUrl);
    }, 'image/jpeg');
  });
};

const ProfileImage = ({ onCropCompleteLast }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [finalCrop, setFinalCrop] = useState(null);
  const [error, setError] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImageSrc(reader.result);
    };
    reader.onerror = () => {
      setError("Failed to read the file. Please try again.");
    };
    reader.readAsDataURL(file);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false,
  });

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onCropFinal = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      setFinalCrop(croppedImage);
      console.log(croppedImage)
      if (onCropCompleteLast) {
        onCropCompleteLast(croppedImage);
      }
    } catch (err) {
      setError("Failed to crop the image. Please try again.");
    }
  }, [imageSrc, croppedAreaPixels, onCropCompleteLast]);

  const reset = () => {
    setImageSrc(null);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCroppedAreaPixels(null);
    setFinalCrop(null);
    setError(null);
  };

  return (
    <div className='profileImageBoxMain'>
      <div className="profileImageBoxMainCol1">
        {!imageSrc ? (
          <div {...getRootProps()} className='profileImageBox'>
            <input {...getInputProps()} />
            <p>Drag & drop an image here, or click to select one</p>
            {error && <p className="error">{error}</p>}
          </div>
        ) : (
          <>
            <div className='profileImageBox'>
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </div>
            <div className="profileImageBoxBtnArea">
              <div onClick={reset} className="profileImageResetBtn" title='Reset Image'><GrPowerReset /></div>
              <div onClick={onCropFinal} className='profileImageSaveBtn'><RiCropLine /> Crop Image</div>
            </div>
            {error && <p className="error">{error}</p>}
          </>
        )}
      </div>
      <div className="profileImageBoxMainCol2">
        {finalCrop && (
          <div className="profileImageView">
            <div className='profileImageViewBox'>
              <h2 className='subTitle'>Cropped Image</h2>
              <img src={finalCrop} alt="Cropped" style={{ maxWidth: '100%' }} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileImage;
