import React from "react";
import { Link, Element, scroller } from 'react-scroll';
import HomeContact from "../home/homeContact";

function TermsConditions() {

  const scrollToSection = (id) => {
    scroller.scrollTo(id, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -90 // Adjust this value based on your header height
    });
  };
  
  return (
    <>
        <section id="faqSection">
            <div className="container">
                <div className="faqSectionMain">
                  <div className="faqSectionHead">
                      <h1 className="mainTitle">Terms & conditions </h1>
                    </div>
                    <div className="faqSectionCol1">
                      <ul>
                        <li>
                          <Link 
                            to="accpetenceOfTerm"
                            className="faqLink"
                            activeClass="faqLinkActive"
                            spy={true}
                            smooth={true}
                            offset={-90}
                            duration={500}
                            onClick={() => scrollToSection('accpetenceOfTerm')}
                            >Accpetence of Term</Link>
                        </li>
                        <li>
                          <Link
                            to="useOfTheWebsite"
                            className="faqLink"
                            activeClass="faqLinkActive"
                            spy={true}
                            smooth={true}
                            offset={-90}
                            duration={500}
                            onClick={() => scrollToSection('useOfTheWebsite')}>Use of the Website</Link>
                        </li>
                        <li>
                          <Link
                            to="intelletualProperty"
                            className="faqLink"
                            activeClass="faqLinkActive"
                            spy={true}
                            smooth={true}
                            offset={-90}
                            duration={500}
                            onClick={() => scrollToSection('intelletualProperty')}
                          >Intelletual Property</Link>
                        </li>
                        <li>
                          <Link
                            to="privacyPolicy"
                            className="faqLink"
                            activeClass="faqLinkActive"
                            spy={true}
                            smooth={true}
                            offset={-90}
                            duration={500}
                            onClick={() => scrollToSection('privacyPolicy')}
                          >Privacy & Policy</Link>
                        </li>
                        <li>
                          <Link
                            to="linkThroughThirdPartyWebsite"
                            className="faqLink"
                            activeClass="faqLinkActive"
                            spy={true}
                            smooth={true}
                            offset={-90}
                            duration={500}
                            onClick={() => scrollToSection('linkThroughThirdPartyWebsite')}
                          >Link through Third Party Website</Link>
                        </li>
                        <li>
                          <Link
                            to="disclaimerWarranties"
                            className="faqLink"
                            activeClass="faqLinkActive"
                            spy={true}
                            smooth={true}
                            offset={-90}
                            duration={500}
                            onClick={() => scrollToSection('disclaimerWarranties')}
                          >Disclaimer  warranties</Link>
                        </li>
                        <li>
                          <Link
                            to="limitationsAndLiabilty"
                            className="faqLink"
                            activeClass="faqLinkActive"
                            spy={true}
                            smooth={true}
                            offset={-90}
                            duration={500}
                            onClick={() => scrollToSection('limitationsAndLiabilty')}
                          >Limitations and Liabilty</Link>
                        </li>
                        <li>
                          <Link
                            to="challegesTermsConditions"
                            className="faqLink"
                            activeClass="faqLinkActive"
                            spy={true}
                            smooth={true}
                            offset={-90}
                            duration={500}
                            onClick={() => scrollToSection('challegesTermsConditions')}
                          >Challeges to Terms and Conditions</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="faqSectionCol2">
                      <div className="faqSectionCol2List">
                        <Element name="accpetenceOfTerm" className="faqSectionCol2ListBox">
                          <h2 className="title">1 - Accpetence Of Term</h2>
                          <p>Welcome to Daniels job Finder. We are committed to protecting your privacy and safeguarding your personal information. This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you interact with our website, products, and services. By using our website and services, you consent to the practices described in this Privacy Policy.</p>
                        </Element>
                        <Element name="useOfTheWebsite" className="faqSectionCol2ListBox">
                          <h2 className="title">2 - Use Of the Website</h2>
                          <h3 className="subTitle">Lisencse:</h3>
                          <p>Daniel Job Finder grants you a limited, non-exclusive, and revocable license to access and use the Website for informational and non-commercial purposes.</p>
                          <h3 className="subTitle">Prohibited Activities:</h3>
                          <p>Attempting to gain unauthorised access to the Website or its servers.</p>
                          <p>Using the Website for any illegal or unethical purposes.</p>
                          <p>Transmitting harmful code or malware.</p>
                        </Element>
                        <Element name="intelletualProperty" className="faqSectionCol2ListBox">
                          <h2 className="title">3 Intelletual Property</h2>
                          <h3 className="subTitle">Copyright:</h3>
                          <p>All content on the Website, including text, graphics, logos, and software, is the property of Daniel job Finder and is protected by copyright laws.</p>
                          <h3 className="subTitle">Trademark:</h3>
                          <p>Any trademarks or logos used on the Website are the property Daniel job Finder  or their respective owners.</p>
                        </Element>
                        <Element name="privacyPolicy" className="faqSectionCol2ListBox">
                          <h2 className="title">4 privacy & Policy</h2>
                          <p>Any trademarks or logos used on the Website are the property Daniel job Finder  or their respective owners.</p>
                        </Element>
                        <Element name="linkThroughThirdPartyWebsite" className="faqSectionCol2ListBox">
                            <h2 className="title">5 Link Through Third Party Website</h2>
                            <p>The Website may contain links to third-party websites. Daniel Job Finder is not responsible for the content or practices of these websites. You access them at your own risk.</p>
                        </Element>
                        <Element name="disclaimerWarranties" className="faqSectionCol2ListBox">
                            <h2 className="title">6 Disclaimer of Warranties</h2>
                            <p>We are committed to protecting your personal information. We employ industry-standard security measures to safeguard your data against unauthorised access, disclosure, alteration, and destruction. However, please be aware that no method of data transmission over the internet is entirely secure, and we cannot guarantee the absolute security of your information.</p>
                        </Element>
                        <Element name="limitationsAndLiabilty" className="faqSectionCol2ListBox">
                            <h2 className="title">7 Limitations And Liability</h2>
                            <p>In no event shall Webandcrafts be liable for any direct, indirect, special, incidental, or consequential damages arising out of or in connection with your use or inability to use the Website.</p>
                        </Element>
                        <Element name="challegesTermsConditions" className="faqSectionCol2ListBox">
                            <h2 className="title">8 Challenges  to  Terms and Conditions </h2>
                            <p>Daniel Job Finder reserves the right to modify these Terms and Conditions at any time. It is your responsibility to check for updates periodically.</p>
                        </Element>
                        
                      </div>
                    </div>
                </div>
            </div>
        </section>

        <HomeContact />
    </>
  );
}

export default TermsConditions;
