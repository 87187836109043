import React from "react";
import { NavLink, Link } from "react-router-dom";
import { BiLogoLinkedin, BiLogoFacebook, BiLogoInstagramAlt, BiLogoYoutube, BiHeart } from "react-icons/bi";
import FooterLogo from '../../assets/images/logo.png'
import FooterEsightLogo from '../../assets/images/esight.png'

const path = "";

function Footer() {

    return (
        <footer>
            <div className="container">
                <div className="footerMain">
                    <div className="footerCol1">
                        <div className="footerLogo">
                            <img src={FooterLogo} alt="" />
                        </div>
                        <div className="footerSocialLinks">
                            <Link to="https://www.linkedin.com/company/daniels-job-finder" target="_blank"><BiLogoLinkedin /></Link>
                            <Link to="https://www.facebook.com/danielsjobfinder" target="_blank"><BiLogoFacebook /></Link>
                            <Link to="https://www.instagram.com/daniels_job_finder?igsh=MWhxcm52Zjl3MmV6dw==" target="_blank"><BiLogoInstagramAlt /></Link>
                            {/* <Link to="" target="_blank"><BiLogoYoutube /></Link> */}
                        </div>
                    </div>
                    <div className="footerCol2">
                        <div className="footerBox1">
                            <h2>Quick Links</h2>
                            <ul>
                                <li>
                                    <NavLink to={`${path}/`}>Home</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${path}/jobs`}>Jobs</NavLink>
                                </li>
                                {/* <li>
                                    <NavLink to={`${path}/countries`}>Countries</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${path}/immigration`}>Immigration</NavLink>
                                </li> */}
                                <li>
                                    <NavLink to={`${path}/service`}>Service</NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="footerBox3">
                            <h2>Company</h2>
                            <ul>
                                <li>
                                    <NavLink to={`${path}/about`}>About</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${path}/blog`}>Blogs</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${path}/testimonials`}>Testimonials</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${path}/gallery`}>Gallery</NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="footerBox2">
                            <h2>Support</h2>
                            <ul>
                                <li>
                                    <NavLink to={`${path}/faq`}>FAQ</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${path}/privacyPollicy`}>Privacy and pollicy</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${path}/termsConditions`}>Terms and conditions</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${path}/contact`}>Contact</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footerStrip">
                    <div className="footerStripCol1">
                        <p>Copyright © 2024 Daniels International Recruitment Pvt Ltd. All Rights Reserved.</p>
                    </div>
                    <div className="footerStripCol2">
                        <p>Designed with</p>
                        <BiHeart />
                        <Link to="https://esightsolutions.com/" target="_blank" className="footerStripLogo">
                            <img src={FooterEsightLogo} alt="Esight Logo" />
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
