import React from "react";
import { Link } from "react-router-dom";
import { lazy } from 'react';
import { GoChevronRight } from "react-icons/go";

import GalleryImg1 from '../../assets/images/gallery/img1.jpg'
import GalleryImg2 from '../../assets/images/gallery/img5.jpg'
import GalleryImg3 from '../../assets/images/gallery/img3.jpg'
import GalleryImg4 from '../../assets/images/gallery/img4.jpg'

const path = "";

const HomeGalleryBox = lazy(() => import("./homeGalleryBox"));

function HomeGallery() {

    return (
        <section id="homeGallery">
            <HomeGalleryBox homeGalleryImg={GalleryImg1} />
            <HomeGalleryBox homeGalleryImg={GalleryImg2} />
            <HomeGalleryBox homeGalleryImg={GalleryImg3} />
            <HomeGalleryBox homeGalleryImg={GalleryImg4} />
            <div className="homeGalleryMain">
                <div className="container">
                    <div className="homeGalleryMainDetails">
                        <h3 className="mainTitle">Explore Our Collection, Moments Captured in Time</h3>
                        <Link to={`${path}/gallery`}>View More <GoChevronRight /></Link>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HomeGallery;
