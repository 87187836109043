import React, { useState } from 'react';
import { IoChevronDownOutline } from "react-icons/io5";
import CustomDropdown from '../settings/customDropdown';

import Select from "react-select";

const courses = [
    { value: "Computer-Science", label: "Computer Science" },
    { value: "Mechanical-Engineering", label: "Mechanical Engineering" },
    { value: "Physics", label: "Physics" },
    { value: "Mathematics", label: "Mathematics" },
    // Add more options here
];

const specializations = [
    { value: "AI", label: "AI" },
    { value: "Machine-Learning", label: "Machine Learning" },
    { value: "Quantum-Computing", label: "Quantum Computing" },
    // Add more options here
];

const courseStart = [
    { value: "2024", label: "2024" },
    { value: "2023", label: "2023" },
    { value: "2022", label: "2022" },
    { value: "2021", label: "2021" },
    { value: "2020", label: "2020" },
    { value: "2019", label: "2019" },
    { value: "2018", label: "2018" },
    { value: "2017", label: "2017" },
    { value: "2016", label: "2016" },
    { value: "2015", label: "2015" },
    { value: "2014", label: "2014" },
    { value: "2013", label: "2013" },
    { value: "2012", label: "2012" },
    { value: "2011", label: "2011" },
    { value: "2010", label: "2010" },
    { value: "2009", label: "2009" },
    { value: "2008", label: "2008" },
    { value: "2007", label: "2007" },
    { value: "2006", label: "2006" },
    { value: "2005", label: "2005" },
    { value: "2004", label: "2004" },
    { value: "2003", label: "2003" },
    { value: "2002", label: "2002" },
    { value: "2001", label: "2001" },
    { value: "2000", label: "2000" },
    { value: "1999", label: "1999" },
    { value: "1998", label: "1998" },
    { value: "1997", label: "1997" },
    { value: "1996", label: "1996" },
    { value: "1995", label: "1995" },
    { value: "1994", label: "1994" },
    { value: "1993", label: "1993" },
    { value: "1992", label: "1992" },
    { value: "1991", label: "1991" },
    { value: "1990", label: "1990" },
    { value: "1989", label: "1989" },
    { value: "1988", label: "1988" },
    { value: "1987", label: "1987" },
    { value: "1986", label: "1986" },
    { value: "1985", label: "1985" },
    { value: "1984", label: "1984" },
    { value: "1983", label: "1983" },
    { value: "1982", label: "1982" },
    { value: "1981", label: "1981" },
    { value: "1980", label: "1980" },
    { value: "1979", label: "1979" },
    { value: "1978", label: "1978" },
    { value: "1977", label: "1977" },
    { value: "1976", label: "1976" },
    { value: "1975", label: "1975" },
    { value: "1974", label: "1974" },
    { value: "1973", label: "1973" },
    { value: "1972", label: "1972" },
    { value: "1971", label: "1971" },
    { value: "1970", label: "1970" },
    { value: "1969", label: "1969" },
    { value: "1968", label: "1968" },
    { value: "1967", label: "1967" },
    { value: "1966", label: "1966" },
    { value: "1965", label: "1965" },
    { value: "1964", label: "1964" },
    { value: "1963", label: "1963" },
    { value: "1962", label: "1962" },
    { value: "1961", label: "1961" },
    { value: "1960", label: "1960" },
    { value: "1959", label: "1959" },
    { value: "1958", label: "1958" },
    { value: "1957", label: "1957" },
    { value: "1956", label: "1956" },
    { value: "1955", label: "1955" },
    { value: "1954", label: "1954" },
    { value: "1953", label: "1953" },
    { value: "1952", label: "1952" },
    { value: "1951", label: "1951" },
    { value: "1950", label: "1950" }
];

const courseEnd = [
    { value: "2035", label: "2035" },
    { value: "2034", label: "2034" },
    { value: "2033", label: "2033" },
    { value: "2032", label: "2032" },
    { value: "2031", label: "2031" },
    { value: "2030", label: "2030" },
    { value: "2029", label: "2029" },
    { value: "2028", label: "2028" },
    { value: "2027", label: "2027" },
    { value: "2026", label: "2026" },
    { value: "2025", label: "2025" },
    { value: "2024", label: "2024" },
    { value: "2023", label: "2023" },
    { value: "2022", label: "2022" },
    { value: "2021", label: "2021" },
    { value: "2020", label: "2020" },
    { value: "2019", label: "2019" },
    { value: "2018", label: "2018" },
    { value: "2017", label: "2017" },
    { value: "2016", label: "2016" },
    { value: "2015", label: "2015" },
    { value: "2014", label: "2014" },
    { value: "2013", label: "2013" },
    { value: "2012", label: "2012" },
    { value: "2011", label: "2011" },
    { value: "2010", label: "2010" },
    { value: "2009", label: "2009" },
    { value: "2008", label: "2008" },
    { value: "2007", label: "2007" },
    { value: "2006", label: "2006" },
    { value: "2005", label: "2005" },
    { value: "2004", label: "2004" },
    { value: "2003", label: "2003" },
    { value: "2002", label: "2002" },
    { value: "2001", label: "2001" },
    { value: "2000", label: "2000" },
    { value: "1999", label: "1999" },
    { value: "1998", label: "1998" },
    { value: "1997", label: "1997" },
    { value: "1996", label: "1996" },
    { value: "1995", label: "1995" },
    { value: "1994", label: "1994" },
    { value: "1993", label: "1993" },
    { value: "1992", label: "1992" },
    { value: "1991", label: "1991" },
    { value: "1990", label: "1990" },
    { value: "1989", label: "1989" },
    { value: "1988", label: "1988" },
    { value: "1987", label: "1987" },
    { value: "1986", label: "1986" },
    { value: "1985", label: "1985" },
    { value: "1984", label: "1984" },
    { value: "1983", label: "1983" },
    { value: "1982", label: "1982" },
    { value: "1981", label: "1981" },
    { value: "1980", label: "1980" },
    { value: "1979", label: "1979" },
    { value: "1978", label: "1978" },
    { value: "1977", label: "1977" },
    { value: "1976", label: "1976" },
    { value: "1975", label: "1975" },
    { value: "1974", label: "1974" },
    { value: "1973", label: "1973" },
    { value: "1972", label: "1972" },
    { value: "1971", label: "1971" },
    { value: "1970", label: "1970" },
    { value: "1969", label: "1969" },
    { value: "1968", label: "1968" },
    { value: "1967", label: "1967" },
    { value: "1966", label: "1966" },
    { value: "1965", label: "1965" },
    { value: "1964", label: "1964" },
    { value: "1963", label: "1963" },
    { value: "1962", label: "1962" },
    { value: "1961", label: "1961" },
    { value: "1960", label: "1960" },
    { value: "1959", label: "1959" },
    { value: "1958", label: "1958" },
    { value: "1957", label: "1957" },
    { value: "1956", label: "1956" },
    { value: "1955", label: "1955" },
    { value: "1954", label: "1954" },
    { value: "1953", label: "1953" },
    { value: "1952", label: "1952" },
    { value: "1951", label: "1951" },
    { value: "1950", label: "1950" }
];

function EducationForm() {
    const [isClearable] = useState(true);
    const [activeIndex, setActiveIndex] = useState(null);
    
    const universities = ["Harvard University", "Stanford University", "MIT", "Caltech"];

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 100,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#d2e2ff' : state.isFocused ? '#eee' : 'white',
            color: state.isSelected ? '#000' : 'inherit',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#000',
        }),
    };

    const toggleSection = (index) => {
        setActiveIndex(prevIndex => (prevIndex === index ? null : index));
    };
    
    return (
    <>
        <div className="eductionForm">
            <div className="eductionFormBox">
                <div className="eductionFormBoxHead" onClick={() => toggleSection(3)}>
                    <h3>Add class 10th</h3>
                    <IoChevronDownOutline />
                </div>
                <div className={`eductionFormBoxBody ${activeIndex === 3 ? 'eductionFormBoxBodyActive' : ''}`}>
                    <div className="formGroup">
                        <label htmlFor="">Education</label>
                        <input type="text" value="10th" disabled />
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">Board</label>
                        <CustomDropdown
                            options={universities}
                            placeholder="Select Board"
                        />
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">Passing out year</label>
                        <div className="formGroupDorpdownList">
                            <div className="formGroupDorpdownListBox">
                                <Select
                                    options={courses}
                                    placeholder="Select Course"
                                    isSearchable={true}
                                    className="customSelect"
                                    isClearable={isClearable}
                                    styles={customStyles}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="eductionFormBox">
                <div className="eductionFormBoxHead" onClick={() => toggleSection(2)}>
                    <h3>Add class 12th</h3>
                    <IoChevronDownOutline />
                </div>
                <div className={`eductionFormBoxBody ${activeIndex === 2 ? 'eductionFormBoxBodyActive' : ''}`}>
                    <div className="formGroup">
                        <label htmlFor="">Education</label>
                        <input type="text" value="12th" disabled />
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">Board</label>
                        <CustomDropdown
                            options={universities}
                            placeholder="Select Board"
                        />
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">Passing out year</label>
                        <div className="formGroupDorpdownList">
                            <div className="formGroupDorpdownListBox">
                                <Select
                                    options={courses}
                                    placeholder="Select Course"
                                    isSearchable={true}
                                    className="customSelect"
                                    isClearable={isClearable}
                                    styles={customStyles}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="eductionFormBox">
                <div className="eductionFormBoxHead" onClick={() => toggleSection(1)}>
                    <h3>Add Masters/Post-Graduation</h3>
                    <IoChevronDownOutline />
                </div>
                <div className={`eductionFormBoxBody ${activeIndex === 1 ? 'eductionFormBoxBodyActive' : ''}`}>
                    <div className="formGroup">
                        <label htmlFor="">Education</label>
                        <input type="text" value="Masters / Post-Graduation" disabled />
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">University/Institute</label>
                        <CustomDropdown
                            options={universities}
                            placeholder="Select University/Institute"
                        />
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">Course</label>
                        <div className="formGroupDorpdownList">
                            <div className="formGroupDorpdownListBox">
                                <Select
                                    options={courses}
                                    placeholder="Select Course"
                                    isSearchable={true}
                                    className="customSelect"
                                    isClearable={isClearable}
                                    styles={customStyles}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">Specialization</label>
                        <div className="formGroupDorpdownList">
                            <div className="formGroupDorpdownListBox">
                                <Select
                                    options={specializations}
                                    placeholder="Select Specializations"
                                    isSearchable={true}
                                    className="customSelect"
                                    isClearable={isClearable}
                                    styles={customStyles}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="formGroup col-1">
                        <label htmlFor="">Course type</label>
                        <div className="formRadioGroup">
                            <div className="formRadioGroupBox radioGroup-Col-3">
                                <input type="radio" id='fullTime2' name="courseType2" />
                                <label htmlFor="fullTime2">
                                    <div className="radioIcon"></div>
                                    <div className="radioText">Full Time</div>
                                </label>
                            </div>
                            <div className="formRadioGroupBox radioGroup-Col-3">
                                <input type="radio" id='partTime2' name="courseType2" />
                                <label htmlFor="partTime2">
                                    <div className="radioIcon"></div>
                                    <div className="radioText">Part Time</div>
                                </label>
                            </div>
                            <div className="formRadioGroupBox radioGroup-Col-3">
                                <input type="radio" id='correspondence2' name="courseType2" />
                                <label htmlFor="correspondence2">
                                    <div className="radioIcon"></div>
                                    <div className="radioText">Distance learning</div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="formGroup col-1">
                        <label htmlFor="">Course duration</label>
                        <div className="formGroupDorpdownList">
                            <div className="formGroupDorpdownListBox">
                                <Select
                                    options={courseStart}
                                    placeholder="Starting Year"
                                    isSearchable={true}
                                    className="customSelect"
                                    isClearable={isClearable}
                                    styles={customStyles}
                                />
                            </div>
                            <div className="formGroupDorpdownListBox">
                                <Select
                                    options={courseEnd}
                                    placeholder="Ending Year"
                                    isSearchable={true}
                                    className="customSelect"
                                    isClearable={isClearable}
                                    styles={customStyles}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="eductionFormBox">
                <div className="eductionFormBoxHead" onClick={() => toggleSection(0)}>
                    <h3>Add Doctorate/PhD</h3>
                    <IoChevronDownOutline />
                </div>
                <div className={`eductionFormBoxBody ${activeIndex === 0 ? 'eductionFormBoxBodyActive' : ''}`}>
                    <div className="formGroup">
                        <label htmlFor="">Education</label>
                        <input type="text" value="Doctorate / PhD" disabled />
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">University/Institute</label>
                        <CustomDropdown
                            options={universities}
                            placeholder="Select University/Institute"
                        />
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">Course</label>
                        <div className="formGroupDorpdownList">
                            <div className="formGroupDorpdownListBox">
                                <Select
                                    options={courses}
                                    placeholder="Select Course"
                                    isSearchable={true}
                                    className="customSelect"
                                    isClearable={isClearable}
                                    styles={customStyles}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">Specialization</label>
                        <div className="formGroupDorpdownList">
                            <div className="formGroupDorpdownListBox">
                                <Select
                                    options={specializations}
                                    placeholder="Select Specializations"
                                    isSearchable={true}
                                    className="customSelect"
                                    isClearable={isClearable}
                                    styles={customStyles}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="formGroup col-1">
                        <label htmlFor="">Course type</label>
                        <div className="formRadioGroup">
                            <div className="formRadioGroupBox radioGroup-Col-3">
                                <input type="radio" id='fullTime' name="courseType" />
                                <label htmlFor="fullTime">
                                    <div className="radioIcon"></div>
                                    <div className="radioText">Full Time</div>
                                </label>
                            </div>
                            <div className="formRadioGroupBox radioGroup-Col-3">
                                <input type="radio" id='partTime' name="courseType" />
                                <label htmlFor="partTime">
                                    <div className="radioIcon"></div>
                                    <div className="radioText">Part Time</div>
                                </label>
                            </div>
                            <div className="formRadioGroupBox radioGroup-Col-3">
                                <input type="radio" id='correspondence' name="courseType" />
                                <label htmlFor="correspondence">
                                    <div className="radioIcon"></div>
                                    <div className="radioText">Distance learning</div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="formGroup col-1">
                        <label htmlFor="">Course duration</label>
                        <div className="formGroupDorpdownList">
                            <div className="formGroupDorpdownListBox">
                                <Select
                                    options={courseStart}
                                    placeholder="Starting Year"
                                    isSearchable={true}
                                    className="customSelect"
                                    isClearable={isClearable}
                                    styles={customStyles}
                                />
                            </div>
                            <div className="formGroupDorpdownListBox">
                                <Select
                                    options={courseEnd}
                                    placeholder="Ending Year"
                                    isSearchable={true}
                                    className="customSelect"
                                    isClearable={isClearable}
                                    styles={customStyles}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default EducationForm;
