import React, { useState, useRef } from "react";
import { Link } from 'react-router-dom';
import { BiPhone } from "react-icons/bi";
import { MdOutlineAlternateEmail, MdOutlineCheckCircle, MdOutlineEdit } from "react-icons/md";
import { TbUserScan } from "react-icons/tb";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { RiDeleteBinLine, RiDownloadLine } from "react-icons/ri";
import { IoAdd } from "react-icons/io5";
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import EducationForm from "../jobs/educationForm";
import PersonalDetailsForm from "../jobs/personalDetailsForm";
import ProfileImage from "./profileImage";

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

import placeHolderImg from '../../assets/images/user.png'

import Select from "react-select";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const locations = [
    { value: "new-york", label: "New York" },
    { value: "san-francisco", label: "San Francisco" },
    { value: "los-angeles", label: "Los Angeles" },
    // Add more options here
];

const place = [
    { value: "new-york", label: "New York" },
    { value: "san-francisco", label: "San Francisco" },
    { value: "los-angeles", label: "Los Angeles" },
    // Add more options here
];

const year = [
    { value: "1year", label: "1 Year" },
    { value: "2years", label: "2 Years" },
    { value: "3years", label: "3 Years" },
    { value: "4years", label: "4 Years" },
    { value: "5years", label: "5 Years" },
    { value: "6years", label: "6 Years" },
    { value: "7years", label: "7 Years" },
    { value: "8years", label: "8 Years" },
    { value: "9years", label: "9 Years" },
    { value: "10years", label: "10 Years" },
    { value: "10Above", label: "10+ Years" },
    // Add more options here
];

const month = [
    { value: "1month", label: "1 Month" },
    { value: "2month", label: "2 Months" },
    { value: "3month", label: "3 Months" },
    { value: "4month", label: "4 Months" },
    { value: "5month", label: "5 Months" },
    { value: "6month", label: "6 Months" },
    { value: "7month", label: "7 Months" },
    { value: "8month", label: "8 Months" },
    { value: "9month", label: "9 Months" },
    { value: "10month", label: "10 Months" },
    { value: "11month", label: "11 Months" },
    // Add more options here
];

function Profile(props) {

    const [resume, setResume] = React.useState(null);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [isClearable] = useState(true);
    const [workStatus, setWorkStatus] = useState('fresher');
    const [resumeList, setResumeList] = useState([
        'Proposal for Zinnia Naturals.docx (1)_removed.pdf',
        'myresumeNew.pdf'
    ]);

    const [educationList, setEducationList] = useState([
        {
            id: 1,
            degree: 'Class 10th',
            board: 'CBSE',
            passingYear: '2024'
        },,
        {
            id: 2,
            degree: 'Class 12th',
            board: 'CBSE',
            passingYear: '2024'
        },
        {
            id: 3,
            degree: 'Masters / Post-Graduation',
            university: 'Harvard University',
            course: 'Computer Science',
            specialization: 'Machine Learning',
            courseType: 'Full Time',
            duration: '2020 - 2024'
        },
        {
            id: 4,
            degree: 'Doctorate / PhD',
            university: 'Harvard University',
            course: 'Computer Science',
            specialization: 'Machine Learning',
            courseType: 'Full Time',
            duration: '2020 - 2024'
        },
    ]);

    const [value, setValue] = useState(0);
    const [itemType, setItemType] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [isProfileImagePopupVisible, setIsProfileImagePopupVisible] = useState(false);
    const [isProfilePopupVisible, setIsProfilePopupVisible] = useState(false);
    const [isResumePopupVisible, setIsResumePopupVisible] = useState(false);
    const [isDeleteResumePopupVisible, setIsDeleteResumePopupVisible] = useState(false);
    const [isEducationPopupVisible, setIsEducationPopupVisible] = useState(false);
    const [isPersonalPopupVisible, setIsPersonalPopupVisible] = useState(false);
    const bodyRef = useRef(document.body);
    const applyJobFormListRef = useRef(null);

    const isMobile = useMediaQuery('(max-width:1024px)');

    const handleChange = (event, newValue) => {
        setValue(newValue);
        window.scrollTo(0, 0); // Scrolls to the top of the page
    };

    const handleDeleteClick = (item, type) => {
        setSelectedItem(item);
        setItemType(type);
        setIsPopupVisible(true);
        document.querySelector('.shimmer').style.display = 'block';
        if (bodyRef.current) bodyRef.current.style.overflow = 'hidden';
        if (applyJobFormListRef.current) applyJobFormListRef.current.style.position = 'inherit';
    };

    const handleCancelClick = () => {
        setIsPopupVisible(false);
        document.querySelector('.shimmer').style.display = 'none';
        if (bodyRef.current) bodyRef.current.style.overflow = 'inherit';
        if (applyJobFormListRef.current) applyJobFormListRef.current.style.position = 'sticky';
    };

    const handleConfirmDeleteClick = () => {
        if (itemType === 'resume') {
            setResumeList(prevList => prevList.filter(resume => resume !== selectedItem));
            toast.success('Resume deleted successfully!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: 'light',
            });
        } else if (itemType === 'education') {
            setEducationList(prevList => prevList.filter(education => education.id !== selectedItem.id));
            toast.success('Education detail deleted successfully!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: 'light',
            });
        }
        setSelectedItem(null);
        setIsPopupVisible(false);
        document.querySelector('.shimmer').style.display = 'none';
        if (bodyRef.current) bodyRef.current.style.overflow = 'inherit';
        if (applyJobFormListRef.current) applyJobFormListRef.current.style.position = 'sticky';
    };

    const profileImageBtnClick = () => {
        setIsProfileImagePopupVisible(true);
        document.querySelector('.shimmer').style.display = 'block';
        if (bodyRef.current) bodyRef.current.style.overflow = 'hidden';
    };

    const handleWorkStatusChange = (event) => {
        setWorkStatus(event.target.id); // 'fresher' or 'experience'
    };

    const editProfileClick = () => {
        setIsProfilePopupVisible(true);
        document.querySelector('.shimmer').style.display = 'block';
        if (bodyRef.current) bodyRef.current.style.overflow = 'hidden';
    };

    const handleProfileCancelClick = () => {
        setIsProfilePopupVisible(false);
        setIsResumePopupVisible(false);
        setIsEducationPopupVisible(false);
        setIsPersonalPopupVisible(false);
        setIsProfileImagePopupVisible(false);
        document.querySelector('.shimmer').style.display = 'none';
        if (bodyRef.current) bodyRef.current.style.overflow = 'inherit';
    };

    const handleProfileSubmitClick = () => {
        if (isProfileImagePopupVisible) {
            toast.success("Profile Image updated successfully!");
            setIsProfileImagePopupVisible(false);
        }
        if (isProfilePopupVisible) {
            toast.success("Profile details updated successfully!");
            setIsProfilePopupVisible(false);
        }
        if (isResumePopupVisible) {
            toast.success("Resume updated successfully!");
            setIsResumePopupVisible(false);
        }
        if (isEducationPopupVisible) {
            toast.success("Education details updated successfully!");
            setIsEducationPopupVisible(false);
        }
        if (isPersonalPopupVisible) {
            toast.success("Personal details updated successfully!");
            setIsPersonalPopupVisible(false);
        }
    
        // Hide shimmer effect
        document.querySelector('.shimmer').style.display = 'none';
    
        // Reset overflow
        if (bodyRef.current) bodyRef.current.style.overflow = 'inherit';
    };

    const editResumeClick = () => {
        setIsResumePopupVisible(true);
        document.querySelector('.shimmer').style.display = 'block';
        if (bodyRef.current) bodyRef.current.style.overflow = 'hidden';
    }

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 100,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#d2e2ff' : state.isFocused ? '#eee' : 'white',
            color: state.isSelected ? '#000' : 'inherit',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#000',
        }),
    };

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        const isValidFileType = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(file.type);
        const isValidFileSize = file.size <= 2 * 1024 * 1024; // 2MB
    
        if (isValidFileType && isValidFileSize) {
            setResume(file);
            setAlertMessage(''); // Clear alert message on valid upload
        } else {
            setAlertMessage('Please upload a valid file type (doc, docx, rtf, pdf) with size less than 2MB.');
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: '.doc,.docx,.pdf,.rtf',
        maxSize: 2 * 1024 * 1024,
    });

    const handleResumeDeleteSubmitClick = () => {
        setIsDeleteResumePopupVisible(true);
        setIsResumePopupVisible(false);
        document.querySelector('.shimmer').style.display = 'block';
        if (bodyRef.current) bodyRef.current.style.overflow = 'hidden';
    };

    const handleResumeDeleteCancelClick = () => {
        setIsDeleteResumePopupVisible(false);
        setIsResumePopupVisible(true);
    };

    const handleResumeDeleteConfirmDeleteClick = () => {
        setResume(null);  // Clear the resume
        setIsDeleteResumePopupVisible(false);
        document.querySelector('.shimmer').style.display = 'none';
        if (bodyRef.current) bodyRef.current.style.overflow = 'inherit';
        toast.success('Resume deleted successfully!', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'light',
        });
    };
    
    const editEducationClick = () => {
        setIsEducationPopupVisible(true);
        document.querySelector('.shimmer').style.display = 'block';
        if (bodyRef.current) bodyRef.current.style.overflow = 'hidden';
    };
    
    const editPersonalClick = () => {
        setIsPersonalPopupVisible(true);
        document.querySelector('.shimmer').style.display = 'block';
        if (bodyRef.current) bodyRef.current.style.overflow = 'hidden';
    };
    
  return (
    <>
        {isPopupVisible && (
            <div className="deleteConfirmPopup">
                <div className="deleteConfirmPopupMain">
                    <h2>
                        {itemType === 'resume' 
                            ? 'Are you sure you want to delete this resume?' 
                            : 'Are you sure you want to delete this education detail?'}
                    </h2>
                    <div className="deleteConfirmPopupFooter">
                        <div className="closeDeleteConfirmPopup" onClick={handleCancelClick}>Cancel</div>
                        <div className="confirmDeleteConfirmPopup" onClick={handleConfirmDeleteClick}>Delete</div>
                    </div>
                </div>
            </div>
        )}

        {/* delete popup resume  */}
        {isDeleteResumePopupVisible && (
            <div className="deleteConfirmPopup">
                <div className="deleteConfirmPopupMain">
                    <h2>Are you sure you want to delete this resume?</h2>
                    <div className="deleteConfirmPopupFooter">
                        <div className="closeDeleteConfirmPopup" onClick={handleResumeDeleteCancelClick}>Cancel</div>
                        <div className="confirmDeleteConfirmPopup" onClick={handleResumeDeleteConfirmDeleteClick}>Delete</div>
                    </div>
                </div>
            </div>
        )}

        {isProfileImagePopupVisible && (
            <div className="profileFormPopup">
                <div className="profileFormPopupHead">
                    <h2 className="subTitle">Profile Image</h2>
                </div>
                <div className="profileFormPopupBody">
                    <form action="">
                        <ProfileImage />
                    </form>
                </div>
                <div className="profileFormPopupFooter">
                    <div className="formBtnArea">
                        <div className="closeProfileFormPopup" onClick={handleProfileCancelClick}>Cancel</div>
                        <button className="submitProfileFormPopup" onClick={handleProfileSubmitClick}>Submit</button>
                    </div>
                </div>
            </div>
        )}

        {isProfilePopupVisible && (
            <div className="profileFormPopup">
                <div className="profileFormPopupHead">
                    <h2 className="subTitle">Profile Details</h2>
                </div>
                <div className="profileFormPopupBody">
                    <form action="">
                        <div className="formGroup">
                            <label htmlFor="">First Name</label>
                            <input type="text" />
                        </div>
                        <div className="formGroup">
                            <label htmlFor="">Last Name</label>
                            <input type="text" />
                        </div>
                        <div className="formGroup">
                            <label htmlFor="">Phone Number</label>
                            <input type="number" />
                        </div>
                        <div className="formGroup">
                            <label htmlFor="">Email</label>
                            <input type="email" />
                        </div>
                        <div className="formGroup">
                            <label htmlFor="">Work Status</label>
                            <div className="formRadioGroup">
                                <div className="formRadioGroupBox">
                                    <input type="radio" id='fresher' name="workStatus" checked={workStatus === 'fresher'} 
                                        onChange={handleWorkStatusChange} />
                                    <label htmlFor="fresher">
                                        <div className="radioIcon"></div>
                                        <div className="radioText">Fresher</div>
                                    </label>
                                </div>
                                <div className="formRadioGroupBox">
                                    <input type="radio" id='experience' name="workStatus" checked={workStatus === 'experience'} 
                                        onChange={handleWorkStatusChange} />
                                    <label htmlFor="experience">
                                        <div className="radioIcon"></div>
                                        <div className="radioText">Experience</div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {workStatus === 'experience' && (
                            <div className="formGroup col-1 experienceArea">
                                <label htmlFor="">Total experience</label>
                                <div className="formGroupDorpdownList">
                                    <div className="formGroupDorpdownListBox">
                                        <Select
                                            options={year}
                                            placeholder="Select Year"
                                            isSearchable={true}
                                            className="customSelect"
                                            isClearable={isClearable}
                                            styles={customStyles}
                                        />
                                    </div>
                                    <div className="formGroupDorpdownListBox">
                                        <Select
                                            options={month}
                                            placeholder="Select Month"
                                            isSearchable={true}
                                            className="customSelect"
                                            isClearable={isClearable}
                                            styles={customStyles}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="formGroup col-1">
                            <label htmlFor="">Current location</label>
                            <div className="formGroupDorpdownList">
                                <div className="formGroupDorpdownListBox">
                                    <Select
                                        options={locations}
                                        placeholder="Select Country"
                                        isSearchable={true}
                                        className="customSelect"
                                        isClearable={isClearable}
                                        styles={customStyles}
                                    />
                                </div>
                                <div className="formGroupDorpdownListBox">
                                    <Select
                                        options={place}
                                        placeholder="Select Place"
                                        isSearchable={true}
                                        className="customSelect"
                                        isClearable={isClearable}
                                        styles={customStyles}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="formGroup col-1">
                            <label htmlFor="">Profile Summary</label>
                            <textarea></textarea>
                        </div>
                    </form>
                </div>
                <div className="profileFormPopupFooter">
                    <div className="formBtnArea">
                        <div className="closeProfileFormPopup" onClick={handleProfileCancelClick}>Cancel</div>
                        <button className="submitProfileFormPopup" onClick={handleProfileSubmitClick}>Submit</button>
                    </div>
                </div>
            </div>
        )}

        {isResumePopupVisible && (
            <div className="profileFormPopup">
                <div className="profileFormPopupHead">
                    <h2 className="subTitle">Upload Resume</h2>
                </div>
                <div className="profileFormPopupBody">
                    <form action="">
                        <div {...getRootProps({ className: 'uploadResumeBox' })}>
                            <input {...getInputProps()} />
                            {
                                isDragActive ?
                                <p>Drop the files here ...</p> :
                                <p>Drag & drop a resume here, or click to select one</p>
                            }
                        </div>
                        {alertMessage && (
                            <div className="uploadResumeAlert">{alertMessage}</div>
                        )}
                        {resume && (
                            <div className="resumeList">
                                <div className="resumeListBox">
                                    <div className="resumeListBoxCol1">
                                        <p>{resume.name}</p>
                                    </div>
                                    <div className="resumeListBoxCol2">
                                        <Link t="" className="resumeListbtn downloadResume" download><RiDownloadLine /></Link>
                                        <button type="button" className='resumeListbtn deleteResume' onClick={handleResumeDeleteSubmitClick}><RiDeleteBinLine /></button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </form>
                </div>
                <div className="profileFormPopupFooter">
                    <div className="formBtnArea">
                        <div className="closeProfileFormPopup" onClick={handleProfileCancelClick}>Cancel</div>
                        <button className="submitProfileFormPopup" onClick={handleProfileSubmitClick}>Submit</button>
                    </div>
                </div>
            </div>
        )}

        {isEducationPopupVisible && (
            <div className="profileFormPopup">
                <div className="profileFormPopupHead">
                    <h2 className="subTitle">Education Details</h2>
                </div>
                <div className="profileFormPopupBody">
                    <form action="">
                        <EducationForm />
                    </form>
                </div>
                <div className="profileFormPopupFooter">
                    <div className="formBtnArea">
                        <div className="closeProfileFormPopup" onClick={handleProfileCancelClick}>Cancel</div>
                        <button className="submitProfileFormPopup" onClick={handleProfileSubmitClick}>Submit</button>
                    </div>
                </div>
            </div>
        )}

        {isPersonalPopupVisible && (
            <div className="profileFormPopup">
                <div className="profileFormPopupHead">
                    <h2 className="subTitle">Personal Details</h2>
                </div>
                <div className="profileFormPopupBody">
                    <form action="">
                        <PersonalDetailsForm />
                    </form>
                </div>
                <div className="profileFormPopupFooter">
                    <div className="formBtnArea">
                        <div className="closeProfileFormPopup" onClick={handleProfileCancelClick}>Cancel</div>
                        <button className="submitProfileFormPopup" onClick={handleProfileSubmitClick}>Submit</button>
                    </div>
                </div>
            </div>
        )}

        <section id="profileSection">
            <div className="container">
                <div className="profileSectionMain">
                    <div className="profileSectionCol1">
                        <div className="profileSectionCol1Head">
                            <h2 className="subTitle">Quick links</h2>
                        </div>
                        <div className="profileSectionCol1Body">
                            <Tabs
                                orientation={isMobile ? "horizontal" : "vertical"}
                                variant="scrollable"
                                value={value}
                                onChange={handleChange}
                                aria-label="Profile tabs"
                            >
                                <Tab label="Profile" {...a11yProps(0)} />
                                <Tab label="Resume" {...a11yProps(1)} />
                                <Tab label="Education" {...a11yProps(2)} />
                                <Tab label="Personal Details" {...a11yProps(3)} />
                            </Tabs>
                        </div>
                    </div>
                    <div className="profileSectionCol2">
                        <TabPanel value={value} index={0}>
                            <div className="profileSectionListBox">
                                <div className="profileSectionListBoxDetails">
                                    <div className="profileSectionListBoxDetailsCol1">
                                        <div className="profileImage" onClick={profileImageBtnClick}>
                                            <img src={placeHolderImg} alt="" />
                                            <div className="profileImageEditBtn">
                                                <IoAdd />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="profileSectionListBoxDetailsCol2">
                                        <h2 className="title">Afsal Nazeer</h2>
                                        <ul>
                                            <li>
                                                <div className="icon">
                                                    <BiPhone />
                                                </div>
                                                <p>+91 9876543210</p>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <MdOutlineAlternateEmail />
                                                </div>
                                                <p>afsal@esightsolutions.com</p>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <HiOutlineLocationMarker />
                                                </div>
                                                <p>Kerala, India</p>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <TbUserScan />
                                                </div>
                                                <p>Experience, 5.5 years</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="profileSectionListBoxDetailsCol3">
                                        <div className="profileSectionEditBtn" onClick={editProfileClick}><MdOutlineEdit /> Edit</div>
                                    </div>
                                    <div className="profileSectionListBoxDetailsCol4">
                                        <p>My Name is AFSAL K T . I am a Web designer and Web developer , and English , Arabic typer . I am looking for this type of job.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="profileSectionListBox">
                                <div className="profileSectionListBoxHead">
                                    <div className="profileSectionListBoxHeadCol1">
                                        <h2 className="subTitle">Resume</h2>
                                    </div>
                                    <div className="profileSectionListBoxHeadCol2">
                                        <div className="profileSectionEditBtn"  onClick={editResumeClick}><MdOutlineEdit /> Edit</div>
                                    </div>
                                </div>
                                <div className="profileSectionListBoxBody">
                                    <div className="resumeList">
                                        {resumeList.map((resume, index) => (
                                            <div className="resumeListBox" key={index}>
                                                <div className="resumeListBoxCol1">
                                                    <p>{resume}</p>
                                                </div>
                                                <div className="resumeListBoxCol2">
                                                    <Link to="" className="resumeListbtn downloadResume" download>
                                                        <RiDownloadLine />
                                                    </Link>
                                                    <button 
                                                        type="button" 
                                                        className='resumeListbtn deleteResume' 
                                                        onClick={() => handleDeleteClick(resume, 'resume')}>
                                                        <RiDeleteBinLine />
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="profileSectionListBox">
                                <div className="profileSectionListBoxHead">
                                    <div className="profileSectionListBoxHeadCol1">
                                        <h2 className="subTitle">Education Details</h2>
                                    </div>
                                    <div className="profileSectionListBoxHeadCol2">
                                        <div className="profileSectionEditBtn" onClick={editEducationClick}><MdOutlineEdit /> Edit</div>
                                    </div>
                                </div>
                                <div className="profileSectionListBoxBody">
                                    <div className="educationList">
                                        {educationList.map((education) => (
                                            <div className="educationListBox" key={education.id}>
                                                <div className="educationListBoxHead">
                                                    <div className="educationListBoxHeadCol1">
                                                        <div className="educationTitle">{education.degree}</div>
                                                    </div>
                                                    <div className="educationListBoxHeadCol2">
                                                        <button 
                                                            type="button" 
                                                            className='resumeListbtn educationDeleteBtn' 
                                                            onClick={() => handleDeleteClick(education, 'education')}>
                                                            <RiDeleteBinLine />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="educationListBoxBody">
                                                    <div className="formGroup">
                                                        <label htmlFor="">University/Institute</label>
                                                        <h4>{education.university}</h4>
                                                    </div>
                                                    <div className="formGroup">
                                                        <label htmlFor="">Course</label>
                                                        <h4>{education.course}</h4>
                                                    </div>
                                                    <div className="formGroup">
                                                        <label htmlFor="">Specialization</label>
                                                        <h4>{education.specialization}</h4>
                                                    </div>
                                                    <div className="formGroup">
                                                        <label htmlFor="">Course type</label>
                                                        <h4>{education.courseType}</h4>
                                                    </div>
                                                    <div className="formGroup">
                                                        <label htmlFor="">Course duration</label>
                                                        <h4>{education.duration}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="profileSectionListBox">
                                <div className="profileSectionListBoxHead">
                                    <div className="profileSectionListBoxHeadCol1">
                                        <h2 className="subTitle">Personal Details</h2>
                                    </div>
                                    <div className="profileSectionListBoxHeadCol2">
                                        <div className="profileSectionEditBtn" onClick={editPersonalClick}><MdOutlineEdit /> Edit</div>
                                    </div>
                                </div>
                                <div className="profileSectionListBoxBody">
                                    <div className="presonalDetails">
                                        <div className="formGroup">
                                            <label htmlFor="">Gender</label>
                                            <h4>Male</h4>
                                        </div>
                                        <div className="formGroup">
                                            <label htmlFor="">Marital status</label>
                                            <h4>Single / Unmarried</h4>
                                        </div>
                                        <div className="formGroup">
                                            <label htmlFor="">Date of birth</label>
                                            <h4>06 July 1998</h4>
                                        </div>
                                        <div className="formGroup">
                                            <label htmlFor="">Permanent address</label>
                                            <h4>Kottiyottuthodi (H), Marayamangalam (PO), Nellaya</h4>
                                        </div>
                                        <div className="formGroup">
                                            <label htmlFor="">Home Town</label>
                                            <h4>Cherpulasseri</h4>
                                        </div>
                                        <div className="formGroup">
                                            <label htmlFor="">Pincode</label>
                                            <h4>679335</h4>
                                        </div>
                                        <div className="formGroup col-1">
                                            <label htmlFor="">Language Proficiency</label>
                                            <div className="languageTable">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Languages</th>
                                                            <th>Proficiency</th>
                                                            <th>Read</th>
                                                            <th>Write</th>
                                                            <th>Speak</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Malayalam</td>
                                                            <td>Expert</td>
                                                            <td><MdOutlineCheckCircle /></td>
                                                            <td><MdOutlineCheckCircle /></td>
                                                            <td><MdOutlineCheckCircle /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Tamil</td>
                                                            <td>Proficiency</td>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td><MdOutlineCheckCircle /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <div className="profileSectionListBox">
                                <div className="profileSectionListBoxHead">
                                    <div className="profileSectionListBoxHeadCol1">
                                        <h2 className="subTitle">Resume</h2>
                                    </div>
                                    <div className="profileSectionListBoxHeadCol2">
                                        <div className="profileSectionEditBtn" onClick={editResumeClick}><MdOutlineEdit /> Edit</div>
                                    </div>
                                </div>
                                <div className="profileSectionListBoxBody">
                                    <div className="resumeList">
                                        {resumeList.map((resume, index) => (
                                            <div className="resumeListBox" key={index}>
                                                <div className="resumeListBoxCol1">
                                                    <p>{resume}</p>
                                                </div>
                                                <div className="resumeListBoxCol2">
                                                    <Link to="" className="resumeListbtn downloadResume" download>
                                                        <RiDownloadLine />
                                                    </Link>
                                                    <button 
                                                        type="button" 
                                                        className='resumeListbtn deleteResume' 
                                                        onClick={() => handleDeleteClick(resume, 'resume')}>
                                                        <RiDeleteBinLine />
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <div className="profileSectionListBox">
                                <div className="profileSectionListBoxHead">
                                    <div className="profileSectionListBoxHeadCol1">
                                        <h2 className="subTitle">Education Details</h2>
                                    </div>
                                    <div className="profileSectionListBoxHeadCol2">
                                        <div className="profileSectionEditBtn" onClick={editEducationClick}><MdOutlineEdit /> Edit</div>
                                    </div>
                                </div>
                                <div className="profileSectionListBoxBody">
                                    <div className="educationList">
                                        {educationList.map((education) => (
                                            <div className="educationListBox" key={education.id}>
                                                <div className="educationListBoxHead">
                                                    <div className="educationListBoxHeadCol1">
                                                        <div className="educationTitle">{education.degree}</div>
                                                    </div>
                                                    <div className="educationListBoxHeadCol2">
                                                        <button 
                                                            type="button" 
                                                            className='resumeListbtn educationDeleteBtn' 
                                                            onClick={() => handleDeleteClick(education, 'education')}>
                                                            <RiDeleteBinLine />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="educationListBoxBody">
                                                    <div className="formGroup">
                                                        <label htmlFor="">University/Institute</label>
                                                        <h4>{education.university}</h4>
                                                    </div>
                                                    <div className="formGroup">
                                                        <label htmlFor="">Course</label>
                                                        <h4>{education.course}</h4>
                                                    </div>
                                                    <div className="formGroup">
                                                        <label htmlFor="">Specialization</label>
                                                        <h4>{education.specialization}</h4>
                                                    </div>
                                                    <div className="formGroup">
                                                        <label htmlFor="">Course type</label>
                                                        <h4>{education.courseType}</h4>
                                                    </div>
                                                    <div className="formGroup">
                                                        <label htmlFor="">Course duration</label>
                                                        <h4>{education.duration}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <div className="profileSectionListBox">
                                <div className="profileSectionListBoxHead">
                                    <div className="profileSectionListBoxHeadCol1">
                                        <h2 className="subTitle">Personal Details</h2>
                                    </div>
                                    <div className="profileSectionListBoxHeadCol2">
                                        <div className="profileSectionEditBtn" onClick={editPersonalClick}><MdOutlineEdit /> Edit</div>
                                    </div>
                                </div>
                                <div className="profileSectionListBoxBody">
                                    <div className="presonalDetails">
                                        <div className="formGroup">
                                            <label htmlFor="">Gender</label>
                                            <h4>Male</h4>
                                        </div>
                                        <div className="formGroup">
                                            <label htmlFor="">Marital status</label>
                                            <h4>Single / Unmarried</h4>
                                        </div>
                                        <div className="formGroup">
                                            <label htmlFor="">Date of birth</label>
                                            <h4>06 July 1998</h4>
                                        </div>
                                        <div className="formGroup">
                                            <label htmlFor="">Permanent address</label>
                                            <h4>Kottiyottuthodi (H), Marayamangalam (PO), Nellaya</h4>
                                        </div>
                                        <div className="formGroup">
                                            <label htmlFor="">Home Town</label>
                                            <h4>Cherpulasseri</h4>
                                        </div>
                                        <div className="formGroup">
                                            <label htmlFor="">Pincode</label>
                                            <h4>679335</h4>
                                        </div>
                                        <div className="formGroup col-1">
                                            <label htmlFor="">Language Proficiency</label>
                                            <div className="languageTable">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Languages</th>
                                                            <th>Proficiency</th>
                                                            <th>Read</th>
                                                            <th>Write</th>
                                                            <th>Speak</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Malayalam</td>
                                                            <td>Expert</td>
                                                            <td><MdOutlineCheckCircle /></td>
                                                            <td><MdOutlineCheckCircle /></td>
                                                            <td><MdOutlineCheckCircle /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Tamil</td>
                                                            <td>Proficiency</td>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td><MdOutlineCheckCircle /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default Profile;
