import React from "react";
import { lazy } from 'react';
import HomeContact from "../home/homeContact";

import AboutImg1 from '../../assets/images/about.jpg'
import elementImg1 from '../../assets/images/elementImg1.png'
import elementImg2 from '../../assets/images/elementImg2.png'
import elementImg3 from '../../assets/images/elementImg3.png'
import elementImg4 from '../../assets/images/elementImg4.png'
import missionImg1 from '../../assets/images/missionImg1.png'
import visionImg1 from '../../assets/images/missionImg2.png'

const HomeAboutThumbnail = lazy(() => import("../home/homeAboutThumbnail"));

function About() {
    
  return (
    <>
        <section id="aboutBannerSection">
            <div className="container">
                <div className="aboutBannerSectionMain">
                    <h1>Daniels Job Finder, <br />Your Path to Career Success</h1>
                </div>
            </div>
        </section>

        <section id="aboutHeadSection">
            <div className="container">
                <div className="aboutHeadSectionMain">
                    <h2>Unlock your potential with <span>Daniels Job Finder</span> connecting you to the perfect career opportunities worldwide.</h2>
                </div>
            </div>
        </section>

        <section id="aboutDetailsSection">
            <div className="container">
                <div className="aboutDetailsSectionMain">
                    <div className="aboutDetailsSectionThumbnail">
                        <HomeAboutThumbnail homeAboutImg={AboutImg1} />
                    </div>
                    <div className="aboutDetailsSectionDetails">
                        <h2 className="mainTitle">A Choice Which Propels You To Excel</h2>
                        <p>Unlock a world of opportunities with Daniel's Recruitment Services! Our expert team specializes in helping individuals achieve their dreams of working, studying, or living abroad. With 20 years of experience in educational services and a team of experts, we guarantee authentic, reliable, and result-driven services.</p>

                        <p>Our network spans across the UK, Canada, Latvia, Malta, Croatia, Australia, Romania, Slovakia, and more. We understand the importance of personalized services, ensuring a seamless experience for our clients.
</p>
<p>At Daniel's Recruitment Services, we value authenticity, integrity, and results. Our motto, "A Choice Which Propels You To Excel!" reflects our commitment to excellence. We're dedicated to helping you achieve your goals and succeed in your international endeavors.</p>

<p>Join us and take the first step towards an exciting international journey! Contact us today to explore opportunities, discuss your aspirations, and let us guide you towards a brighter future. With Daniel's Recruitment Services, you can trust that you're in good hands. Let's propel your career and life forward – together!
</p>
                        {/* <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima expedita, enim dolorem non saepe quisquam voluptate inventore. Dolorum, eveniet. Rerum dolor quam molestiae veniam similique numquam vitae, nulla reiciendis quos.</p> */}
                    </div>
                </div>
            </div>
        </section>

        <section id="aboutAtchive">
            <div className="container">
                <div className="aboutAtchiveMain">
                    <div className="aboutAtchiveBox">
                        <div className="aboutAtchiveBoxIcon">
                            <img src={elementImg1} alt="" />
                        </div>
                        <div className="aboutAtchiveBoxDetails">
                            <h2>1000+</h2>
                            <p>Jobs posted</p>
                        </div>
                    </div>
                    <div className="aboutAtchiveBox">
                        <div className="aboutAtchiveBoxIcon">
                            <img src={elementImg2} alt="" />
                        </div>
                        <div className="aboutAtchiveBoxDetails">
                            <h2>5000+</h2>
                            <p>Worldwide users</p>
                        </div>
                    </div>
                    <div className="aboutAtchiveBox">
                        <div className="aboutAtchiveBoxIcon">
                            <img src={elementImg3} alt="" />
                        </div>
                        <div className="aboutAtchiveBoxDetails">
                            <h2>200 +</h2>
                            <p>Top Companies</p>
                        </div>
                    </div>
                    <div className="aboutAtchiveBox">
                        <div className="aboutAtchiveBoxIcon">
                            <img src={elementImg4} alt="" />
                        </div>
                        <div className="aboutAtchiveBoxDetails">
                            <h2>99.9 %</h2>
                            <p>Happy users</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="aboutMissionVision">
            <div className="container">
                <div className="aboutMissionVisionMain">
                    <div className="aboutMissionVisionBox">
                        <div className="aboutMissionVisionBoxDetails">
                            <h2 className="mainTitle">Our mission</h2>
                            <p>At Daniel's Recruitment Services, our mission is to empower individuals to achieve their international aspirations by providing personalized, authentic, and result-driven recruitment and immigration solutions. We strive to bridge the gap between talent and opportunity, fostering global connections and growth.</p>
                        </div>
                        <div className="aboutMissionVisionBoxThumbnail">
                            <img src={missionImg1} alt="" />
                        </div>
                    </div>
                    <div className="aboutMissionVisionBox">
                        <div className="aboutMissionVisionBoxThumbnail">
                            <img src={visionImg1} alt="" />
                        </div>
                        <div className="aboutMissionVisionBoxDetails">
                            <h2 className="mainTitle">Our vision</h2>
                            <p>Our vision is to be the leading global recruitment and immigration services provider, renowned for excellence, integrity, and customer satisfaction. We envision a world where borders are bridged, and opportunities are limitless, enabling individuals to thrive and succeed in their chosen paths.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <HomeContact />
    </>
  );
}

export default About;
