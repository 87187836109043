import React from "react";
import { Suspense, lazy } from 'react';
import HomeContact from "../home/homeContact";
import BackBtn from "../settings/backBtn";
import { GoChevronRight, GoArrowRight, GoArrowLeft } from "react-icons/go";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import bannerImg1 from '../../assets/images/country/img1.jpg'
import companyLogo1 from '../../assets/images/compnay/logoIcon1.png'
import companyLogo2 from '../../assets/images/compnay/img1.webp'
import companyLogo3 from '../../assets/images/compnay/img2.svg'
import companyLogo4 from '../../assets/images/compnay/img3.png'
import companyLogo5 from '../../assets/images/compnay/img4.webp'
import companyLogo6 from '../../assets/images/compnay/img5.webp'

import serviceImg1 from '../../assets/images/service/img1.jpg'
import serviceImg2 from '../../assets/images/service/img2.jpg'
import serviceImg3 from '../../assets/images/service/img3.jpg'
import serviceImg4 from '../../assets/images/service/img4.jpg'
import serviceImg5 from '../../assets/images/service/img5.jpg'
import serviceImg6 from '../../assets/images/service/img6.jpg'
import serviceImg7 from '../../assets/images/service/img7.jpg'
import serviceImg8 from '../../assets/images/service/img8.jpg'
import serviceImg9 from '../../assets/images/service/img9.jpg'

const path = "";

const JobBox = lazy(() => import("../home/jobBox"));

const HomeServiceBox = lazy(() => import("../home/homeServiceBox"));


function CountryDetails() {

    const settings = {
        dots: false,
        infinite: false,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    
  return (
    <>
        <section id="countryDetailsBanner">
            <div className="container">
                <BackBtn />
                <div className="countryDetailsBannerMain">
                    <div className="countryDetailsBannerImg">
                        <img src={bannerImg1} alt="" />
                    </div>
                    <h1>Canada</h1>
                </div>
            </div>
        </section>

        <section id="countryDetails">
            <div className="container">
                <h2 className="title">Related Jobs</h2>
                <div className="countryDetailsMain">
                    <Suspense>
                        <JobBox jobLink={`${path}/jobDetails`} jobCompanyImg={companyLogo1} jobCompanyName="Esight Business Solutions Pvt Ltd" jobTitle="Web Developer" jobLocation="Angamaly, Kerala" jobExperiance="2 - 5 Yrs" jobSalary="30k - 50k" jobOpening="2" jobPostedDate="1" />
                    </Suspense>
                    <Suspense>
                        <JobBox jobLink={`${path}/jobDetails`} jobCompanyImg={companyLogo2} jobCompanyName="Google Pvt Ltd" jobTitle="Google Cloud Consulting" jobLocation="UK" jobExperiance="1 - 2 Yrs" jobSalary="1 L - 1.5 L" jobOpening="1" jobPostedDate="1" />
                    </Suspense>
                    <Suspense>
                        <JobBox jobLink={`${path}/jobDetails`} jobCompanyImg={companyLogo3} jobCompanyName="Amazon" jobTitle="Truck Driver" jobLocation="Kerala, India" jobExperiance="5 - 8 Yrs" jobSalary="1 L - 2 L" jobOpening="3" jobPostedDate="3" />
                    </Suspense>
                    <Suspense>
                        <JobBox jobLink={`${path}/jobDetails`} jobCompanyImg={companyLogo4} jobCompanyName="Lulu Group International" jobTitle="Assistant Manager" jobLocation="Dubai" jobExperiance="1 - 2 Yrs" jobSalary="50k - 75k" jobOpening="1" jobPostedDate="2" />
                    </Suspense>
                    <Suspense>
                        <JobBox jobLink={`${path}/jobDetails`} jobCompanyImg={companyLogo5} jobCompanyName="Bayerische Motoren Werke AG" jobTitle="Sales Executive" jobLocation="USA" jobExperiance="3 - 4 Yrs" jobSalary="2 L - 3 L" jobOpening="5" jobPostedDate="3" />
                    </Suspense>
                    <Suspense>
                        <JobBox jobLink={`${path}/jobDetails`} jobCompanyImg={companyLogo6} jobCompanyName="X Corp" jobTitle="Prompt Engineer" jobLocation="California, US" jobExperiance="1 - 2 Yrs" jobSalary="75k - 1 L" jobOpening="10" jobPostedDate="4" />
                    </Suspense>
                </div>
            </div>
        </section>

        <section id="immigrationList">
            <div className="container">
                <div className="immigrationListHead">
                    <h2 className="mainTitle">We specialize in immigration and we do it with excellence</h2>
                </div>
                <div className="immigrationListMain">
                    <Slider {...settings}>
                        <div className="item">
                            <HomeServiceBox homeServiceBoxLink={`${path}/provideinnerServiceJobs`} homeServiceBoxThumbnail={serviceImg1} homeServiceBoxTitle="Provide Jobs Canada" homeServiceBoxDesc="Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit incidunt perferendis corporis voluptas totam vero? Asperiores cum eos quaerat obcaecati porro fugit ipsum, quibusdam excepturi illo exercitationem enim nobis necessitatibus?" />
                        </div>
                        <div className="item">
                            <HomeServiceBox homeServiceBoxLink={`${path}/innerService`} homeServiceBoxThumbnail={serviceImg2} homeServiceBoxTitle="Work Permit to Canada" homeServiceBoxDesc="Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit incidunt perferendis corporis voluptas totam vero? Asperiores cum eos quaerat obcaecati porro fugit ipsum, quibusdam excepturi illo exercitationem enim nobis necessitatibus?" />
                        </div>
                        <div className="item">
                            <HomeServiceBox homeServiceBoxLink={`${path}/innerService`} homeServiceBoxThumbnail={serviceImg3} homeServiceBoxTitle="Permanent Residence to Canada" homeServiceBoxDesc="Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit incidunt perferendis corporis voluptas totam vero? Asperiores cum eos quaerat obcaecati porro fugit ipsum, quibusdam excepturi illo exercitationem enim nobis necessitatibus?" />
                        </div>
                        <div className="item">
                            <HomeServiceBox homeServiceBoxLink={`${path}/innerService`} homeServiceBoxThumbnail={serviceImg4} homeServiceBoxTitle="Job Placement to Canada" homeServiceBoxDesc="Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit incidunt perferendis corporis voluptas totam vero? Asperiores cum eos quaerat obcaecati porro fugit ipsum, quibusdam excepturi illo exercitationem enim nobis necessitatibus?" />
                        </div>
                        <div className="item">
                            <HomeServiceBox homeServiceBoxLink={`${path}/innerService`} homeServiceBoxThumbnail={serviceImg5} homeServiceBoxTitle="Assistance in International Passports" homeServiceBoxDesc="Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit incidunt perferendis corporis voluptas totam vero? Asperiores cum eos quaerat obcaecati porro fugit ipsum, quibusdam excepturi illo exercitationem enim nobis necessitatibus?" />
                        </div>
                        <div className="item">
                            <HomeServiceBox homeServiceBoxLink={`${path}/innerService`} homeServiceBoxThumbnail={serviceImg6} homeServiceBoxTitle="Assistance in Dual Citizenship" homeServiceBoxDesc="Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit incidunt perferendis corporis voluptas totam vero? Asperiores cum eos quaerat obcaecati porro fugit ipsum, quibusdam excepturi illo exercitationem enim nobis necessitatibus?" />
                        </div>
                        <div className="item">
                            <HomeServiceBox homeServiceBoxLink={`${path}/innerService`} homeServiceBoxThumbnail={serviceImg7} homeServiceBoxTitle="Assistance in Green Card Canada" homeServiceBoxDesc="Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit incidunt perferendis corporis voluptas totam vero? Asperiores cum eos quaerat obcaecati porro fugit ipsum, quibusdam excepturi illo exercitationem enim nobis necessitatibus?" />
                        </div>
                        <div className="item">
                            <HomeServiceBox homeServiceBoxLink={`${path}/innerService`} homeServiceBoxThumbnail={serviceImg8} homeServiceBoxTitle="Study Abroad Programmes to Canada" homeServiceBoxDesc="Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit incidunt perferendis corporis voluptas totam vero? Asperiores cum eos quaerat obcaecati porro fugit ipsum, quibusdam excepturi illo exercitationem enim nobis necessitatibus?" />
                        </div>
                        <div className="item">
                            <HomeServiceBox homeServiceBoxLink={`${path}/innerService`} homeServiceBoxThumbnail={serviceImg9} homeServiceBoxTitle="Visit Visa/Business Visa Assistance to Canada" homeServiceBoxDesc="Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit incidunt perferendis corporis voluptas totam vero? Asperiores cum eos quaerat obcaecati porro fugit ipsum, quibusdam excepturi illo exercitationem enim nobis necessitatibus?" />
                        </div>
                    </Slider>
                </div>
            </div>
        </section>
        <HomeContact />
    </>
  );
}

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <GoArrowRight />
        </button>
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <GoArrowLeft />
        </button>
    );
}

export default CountryDetails;
