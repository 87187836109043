import React, { useState, useEffect, Suspense, lazy } from "react";
import HomeContact from "../home/homeContact";
import api, { baseURL } from '../axiosInterceptor/axiosInterceptorC'; // Using axiosInterceptor for API requests

const HomeBlogBox = lazy(() => import("../home/homeBlogBox"));

const path = "";

function Blog() {
  // State for blogs
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch blogs data from API
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await api.get(`${baseURL}/blog_list_front`); // Replace with your actual API endpoint
        setBlogs(response.data.data);
        console.log(response.data.data)
      } catch (error) {
        setError("Failed to fetch blog data");
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  if (loading) {
    return <>
            <section id="blogSection">
            <div className="container">
                <div className="blogSectionTitle">
                <h2 className="title">Latest Blogs</h2>
                <h3 className="mainTitle">Unlocking Success, Simple Habits, Big Results</h3>
                </div>
                <div className="blogSectionList">
                
                    <Suspense key={1}>
                        <HomeBlogBox 
                            homeBlogLink={`${path}/innerBlog/${''}`} 
                            homeBlogImg={'https://danielsjobfinder.com/demo3/public/hi'} // Use a fallback image if `imageUrl` is unavailable
                            homeBlogTime={''} 
                            homeBlogTitle={''} 
                            homeBlogDesc={''}
                        />
                    </Suspense>
                    <Suspense key={2}>
                        <HomeBlogBox 
                            homeBlogLink={`${path}/innerBlog/${''}`} 
                            homeBlogImg={'https://danielsjobfinder.com/demo3/public/hi'} // Use a fallback image if `imageUrl` is unavailable
                            homeBlogTime={''} 
                            homeBlogTitle={''} 
                            homeBlogDesc={''}
                        />
                    </Suspense>
                    <Suspense key={3}>
                        <HomeBlogBox 
                            homeBlogLink={`${path}/innerBlog/${''}`} 
                            homeBlogImg={'https://danielsjobfinder.com/demo3/public/hi'} // Use a fallback image if `imageUrl` is unavailable
                            homeBlogTime={''} 
                            homeBlogTitle={''} 
                            homeBlogDesc={''}
                        />
                    </Suspense>
                    <Suspense key={4}>
                        <HomeBlogBox 
                            homeBlogLink={`${path}/innerBlog/${''}`} 
                            homeBlogImg={'https://danielsjobfinder.com/demo3/public/hi'} // Use a fallback image if `imageUrl` is unavailable
                            homeBlogTime={''} 
                            homeBlogTitle={''} 
                            homeBlogDesc={''}
                        />
                    </Suspense>
                    <Suspense key={5}>
                        <HomeBlogBox 
                            homeBlogLink={`${path}/innerBlog/${''}`} 
                            homeBlogImg={'https://danielsjobfinder.com/demo3/public/hi'} // Use a fallback image if `imageUrl` is unavailable
                            homeBlogTime={''} 
                            homeBlogTitle={''} 
                            homeBlogDesc={''}
                        />
                    </Suspense>
                    <Suspense key={6}>
                        <HomeBlogBox 
                            homeBlogLink={`${path}/innerBlog/${''}`} 
                            homeBlogImg={'https://danielsjobfinder.com/demo3/public/hi'} // Use a fallback image if `imageUrl` is unavailable
                            homeBlogTime={''} 
                            homeBlogTitle={''} 
                            homeBlogDesc={''}
                        />
                    </Suspense>
               
                </div>
            </div>
            </section>

            <HomeContact />
        </>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <section id="blogSection">
        <div className="container">
          <div className="blogSectionTitle">
            <h2 className="title">Latest Blogs</h2>
            <h3 className="mainTitle">Unlocking Success, Simple Habits, Big Results</h3>
          </div>
          <div className="blogSectionList">
            {blogs.map((blog) => (
              <Suspense key={blog.id}>
                <HomeBlogBox 
                  homeBlogLink={`${path}/innerBlog/${blog.id}`} 
                  homeBlogImg={`https://danielsjobfinder.com/demo3/public/${blog.blog_image}`} // Use a fallback image if `imageUrl` is unavailable
                  homeBlogTime={blog.added_date} 
                  homeBlogTitle={blog.blog_title} 
                  homeBlogDesc={blog.blog_description}
                />
              </Suspense>
            ))}
          </div>
        </div>
      </section>

      <HomeContact />
    </>
  );
}

export default Blog;
