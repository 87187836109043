import React, { useState, useEffect } from 'react';
import { FaChevronDown } from "react-icons/fa6";

function CustomDropdown({ options, label, placeholder }) {
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    const filtered = options.filter(option =>
      option.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredOptions(filtered);
    setShowDropdown(filtered.length > 0);
  };

  const handleOptionClick = (option) => {
    setInputValue(option);
    setSelectedOption(option);
    setShowDropdown(false);
  };

  const handleFocus = () => {
    if (filteredOptions.length > 0) {
      setShowDropdown(true);
    }
  };

  const handleBlur = () => {
    setTimeout(() => setShowDropdown(false), 200);
  };

  return (
      <div className="customDropdown">
        <div className="customDropdownHead">
            <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                placeholder={placeholder}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
            <div className="customDropdownHeadcon">
                <FaChevronDown />
            </div>
        </div>
        {showDropdown && (
          <ul className="dropdownMenu">
            {filteredOptions.map((option, index) => (
              <li
                key={index}
                onClick={() => handleOptionClick(option)}
                className={option === selectedOption ? 'selected' : ''}
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
  );
}

export default CustomDropdown;
