import React, { useState, useEffect } from "react";
import HomeContact from "../home/homeContact";
import { ImageGallery } from "react-image-grid-gallery";
import axios from 'axios';
import api, { baseURL } from '../axiosInterceptor/axiosInterceptorC'; // Axios interceptor

function GalleryPage() {
const imageDemo =  [{ src: 'https://danielsjobfinder.com/demo3/hi' },
                    { src: 'https://danielsjobfinder.com/demo3/hi' },
                    { src: 'https://danielsjobfinder.com/demo3/hi' },
                    { src: 'https://danielsjobfinder.com/demo3/hi' },
                    ]
  const [imagesArray, setImagesArray] = useState(imageDemo);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch gallery images from API
  useEffect(() => {
    const fetchGalleryImages = async () => {
      try {
        const response = await api.get(`${baseURL}/gallery-list`); // Replace with actual API endpoint
        // Assuming the response data is an array of image URLs
        console.log(response)
        const images = response.data.data.map(img => ({ src: 'https://danielsjobfinder.com/demo3/public/'+img.gallery_image }));
        setImagesArray(images);
      } catch (error) {
        console.log(error)
        setError('Failed to fetch gallery images');
      } finally {
        setLoading(false);
      }
    };

    fetchGalleryImages();
  }, []);

  if (loading) {
    return <>
            <section id="gallery">
              <div className="container">
                <div className="galleryTitle">
                  <h2 className="title">Gallery</h2>
                  <h3 className="mainTitle">Real Stories of Success and Satisfaction</h3>
                </div>
                <div className="galleryList">
                  
                </div>
              </div>
            </section>
            <HomeContact />
          </>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <section id="gallery">
        <div className="container">
          <div className="galleryTitle">
            <h2 className="title">Testimonials</h2>
            <h3 className="mainTitle">Real Stories of Success and Satisfaction</h3>
          </div>
          <div className="galleryList">
            <ImageGallery
              imagesInfoArray={imagesArray}
              columnCount={"auto"}
              columnWidth={300}
              gapSize={10}
            />
          </div>
        </div>
      </section>
      <HomeContact />
    </>
  );
}

export default GalleryPage;
