import JobBanner from "./jobBanner";
import JobsWraper from "./jobsWraper";
import HomeContact from "../home/homeContact";


function Jobs() {
    
  return (
    <>
        <JobBanner jobBannerTitle="Featured Jobs" />
        <JobsWraper />
        <HomeContact />
    </>
  );
}

export default Jobs;
