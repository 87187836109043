import React from "react";
import { Link, Element, scroller } from 'react-scroll';
import HomeContact from "../home/homeContact";

function PrivacyPollicy() {

  const scrollToSection = (id) => {
    scroller.scrollTo(id, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -90 // Adjust this value based on your header height
    });
  };
  
  return (
    <>
        <section id="faqSection">
            <div className="container">
                <div className="faqSectionMain">
                  <div className="faqSectionHead">
                      <h1 className="mainTitle">Privacy & Cookie Policy</h1>
                    </div>
                    <div className="faqSectionCol1">
                      <ul>
                        <li>
                          <Link 
                            to="introduction"
                            className="faqLink"
                            activeClass="faqLinkActive"
                            spy={true}
                            smooth={true}
                            offset={-90}
                            duration={500}
                            onClick={() => scrollToSection('introduction')}
                            >Introduction</Link>
                        </li>
                        <li>
                          <Link
                            to="informartionWeCollect"
                            className="faqLink"
                            activeClass="faqLinkActive"
                            spy={true}
                            smooth={true}
                            offset={-90}
                            duration={500}
                            onClick={() => scrollToSection('informartionWeCollect')}>Informartion We Collect</Link>
                        </li>
                        <li>
                          <Link
                            to="howWeUseYourInformation"
                            className="faqLink"
                            activeClass="faqLinkActive"
                            spy={true}
                            smooth={true}
                            offset={-90}
                            duration={500}
                            onClick={() => scrollToSection('howWeUseYourInformation')}
                          >How we Use Your  Information</Link>
                        </li>
                        <li>
                          <Link
                            to="dataSecurity"
                            className="faqLink"
                            activeClass="faqLinkActive"
                            spy={true}
                            smooth={true}
                            offset={-90}
                            duration={500}
                            onClick={() => scrollToSection('dataSecurity')}
                          >Data Security</Link>
                        </li>
                        <li>
                          <Link
                            to="shareingYourInformation"
                            className="faqLink"
                            activeClass="faqLinkActive"
                            spy={true}
                            smooth={true}
                            offset={-90}
                            duration={500}
                            onClick={() => scrollToSection('shareingYourInformation')}
                          >Shareing Your Information</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="faqSectionCol2">
                      <div className="faqSectionCol2List">
                        <Element name="introduction" className="faqSectionCol2ListBox">
                          <h2 className="title">Introduction</h2>
                          <p>Welcome to Daniels job Finder. We are committed to protecting your privacy and safeguarding your personal information. This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you interact with our website, products, and services. By using our website and services, you consent to the practices described in this Privacy Policy.</p>
                        </Element>
                        <Element name="informartionWeCollect" className="faqSectionCol2ListBox">
                          <h2 className="title">Information We Collect:</h2>
                          <p>We may collect and process the following types of personal information:</p>
                          <h3 className="subTitle">Contact  Information</h3>
                          <p>This includes your name, email address, phone number, and postal address, which we collect when you communicate with us or use our services.</p>
                          <h3 className="subTitle">Usage Information</h3>
                          <p>We may collect information about how you interact with our website and services, such as IP addresses, device information, browser type, and pages visited. This data helps us improve our services and customise your user experience.</p>
                        </Element>
                        <Element name="howWeUseYourInformation" className="faqSectionCol2ListBox">
                          <h2 className="title">How We Use Your Information</h2>
                          <p>We use your personal information for the following purposes:</p>
                          <h3 className="subTitle">Providing Services:</h3>
                          <p>We use your information to deliver the products and services you request, including processing orders and providing customer support.</p>
                          <h3 className="subTitle">Improving Services:</h3>
                          <p>We may analyse usage data to enhance our website and services, tailor content to your preferences, and develop new features.</p>
                          <h3 className="subTitle">Communication:</h3>
                          <p>We may use your contact information to send you updates, promotional offers, newsletters, and important announcements. You can opt-out of these communications at any time.</p>
                        </Element>
                        <Element name="dataSecurity" className="faqSectionCol2ListBox">
                          <h2 className="title">Data Security</h2>
                          <p>We are committed to protecting your personal information. We employ industry-standard security measures to safeguard your data against unauthorised access, disclosure, alteration, and destruction. However, please be aware that no method of data transmission over the internet is entirely secure, and we cannot guarantee the absolute security of your information.</p>
                        </Element>
                        <Element name="shareingYourInformation" className="faqSectionCol2ListBox">
                            <h2 className="title">Shareing Your Information</h2>
                            <p>We do not sell your personal information to third parties. However, we may share your data with trusted third parties for the following purposes:</p>
                            <h3 className="subTitle">Service Provider:</h3>
                            <p>We may share information with trusted third-party service providers who assist us in operating our website and providing our services.</p>
                            <h3 className="subTitle">Legal Requirements:</h3>
                            <p>We may disclose your information to comply with legal obligations or to protect our rights, privacy, safety, or property, and those of our customers and the public.</p>
                        </Element>
                      </div>
                    </div>
                </div>
            </div>
        </section>

        <HomeContact />
    </>
  );
}

export default PrivacyPollicy;
