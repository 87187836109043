import React, { useRef, useState,useEffect } from 'react';
import { Suspense, lazy } from 'react';
import { Link } from "react-router-dom";
import JobFilter from './jobFilter';
import { RiSearchLine, RiFilter3Line } from "react-icons/ri";
import { IoCallOutline } from "react-icons/io5";
import { FiFilter } from "react-icons/fi";
import api, { baseURL } from '../axiosInterceptor/axiosInterceptorC';

import companyLogo1 from '../../assets/images/compnay/logoIcon1.png'
import companyLogo2 from '../../assets/images/compnay/img1.webp'
import companyLogo3 from '../../assets/images/compnay/img2.svg'
import companyLogo4 from '../../assets/images/compnay/img3.png'
import companyLogo5 from '../../assets/images/compnay/img4.webp'
import companyLogo6 from '../../assets/images/compnay/img5.webp'
import companyLogo7 from '../../assets/images/logo.png'
import axios from 'axios';
const path = "";

const JobBox = lazy(() => import("../home/jobBox"));

function JobsWraper() {
  const jobFilterRef = useRef(null);
  
  const filterBtnClick = () => {
    const jobWraperFilter = document.querySelector('.jobWraperFilter');
    const shimmer = document.querySelector('.shimmer');
    const body = document.querySelector('body');

    if (jobWraperFilter) {
      jobWraperFilter.style.left = '0';
    }
    if (shimmer) {
        shimmer.style.display = 'block';
    }
    if (body) {
        body.style.overflow = 'hidden';
    }
  }

  const handleFilterItemClick = (filterType) => {
    if (jobFilterRef.current) {
      jobFilterRef.current.toggleDropdown(filterType);
      filterBtnClick(); // Open the filter overlay
    }
  };

  const [jobData, setJobData] = useState([]);
  //fetch job details
  useEffect(() => {
    axios.get(`${baseURL}/fetch_job_details_all`)
      .then(response => {
        console.log(response.data)
        setJobData(response.data);
      })
      .catch(error => {
        console.error('Error fetching job data:', error);
      });
  }, []);
  const companyLogoPath = 'https://danielsjobfinder.com/demo3/public/';

  return (
    <>
      <section id="jobsWraper">
        <div className="jobFilterBtnStrip">
          <div className="container">
            <div className="jobFilterBtnStripMain">
              <div className="filterBtn" onClick={filterBtnClick}><FiFilter /></div>
              <ul>
                <li onClick={() => handleFilterItemClick('keyword')}>Keyword</li>
                <li onClick={() => handleFilterItemClick('position')}>Position Name</li>
                <li onClick={() => handleFilterItemClick('jobTitle')}>Job Title</li>
                <li onClick={() => handleFilterItemClick('location')}>Location</li>
                <li onClick={() => handleFilterItemClick('jobType')}>Job Type</li>
                <li onClick={() => handleFilterItemClick('sort')}>Sort By</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container">
                  <div className="jobsWraperMain">
                    <JobFilter ref={jobFilterRef} />
        <div className="jobWraperList">
          {jobData.length > 0 ? (
            
            jobData.map((job, index) => (
              
              <Suspense key={index} fallback={<div>Loading...</div>}>
              <JobBox
                jobLink={`${path}/jobDetails/${job.id}`}  // Use job ID or some unique identifier
                jobCompanyImg={companyLogo7}  // Pass the image URL
                jobCompanyName={job.company_name}  // Assuming the API returns 'company_name'
                jobTitle={job.job_title}  // Assuming the API returns 'job_title'
                jobLocation={job.location_val}  // Assuming the API returns 'location'
                jobExperiance={job.experience_range}  // Adjust according to your data structure
                jobSalary={`${job.from_amount} - ${job.to_amount}${job.salary_currency}`}  // Assuming salary range is provided
                jobOpening={job.no_openings}  // Number of openings
                jobPostedDate={job.days_ago}  // Use 'days_ago' from the backend
                applyJobLink={`${path}/applyJobForm/${job.id}`}
              />
            </Suspense>


            ))
          ) : (
            <div className="noDataFound">
              <div className="noDataFoundIcon">
                <RiSearchLine />
              </div>
              <div className="noDataFoundDetails">
                <h3>No results</h3>
                <p>If you don’t find what you are looking for, call/WhatsApp us on +971-11-1234567 and we will be happy to personally find a good fit for you.</p>
                <Link to="tel:+971111234567" className="noDataFoundClearFIlterBtn">
                  <IoCallOutline /> Call Now
                </Link>
              </div>
            </div>
          )}
          
          <div className="jobWraperLoadMore">
            <div className="jobWraperLoadMoreBtn">Show More</div>
          </div>
        </div>

          </div>
        </div>
      </section>
    </>
  );
}

export default JobsWraper;
