import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/style.css';
import './assets/css/main.css';
import Nav from "./component/nav/nav";
import Home from './component/home/home';
import Footer from './component/footer/footer';
import Jobs from './component/jobs/jobs';
import JobDetails from './component/jobs/jobDetails';
import ApplyJobForm from './component/jobs/applyJobForm';
import Login from './component/login/login';
import OTP from './component/login/otp';
import Register from './component/login/register';
import Profile from './component/profile/profile';
import SavedJobs from './component/jobs/savedJobs';
import AppliedJobs from './component/jobs/appliedJobs';
import Countries from './component/otherPages/countries';
import CountryDetails from './component/otherPages/.countryDetails';
import About from './component/otherPages/about';
import Service from './component/otherPages/service';
import Blog from './component/otherPages/blog';
import InnerBlog from './component/otherPages/innerBlog';
import Testimonials from './component/otherPages/testimonials';
import GalleryPage from './component/otherPages/gallery';
import Contact from './component/otherPages/contact';
import InnerService from './component/otherPages/innerService';
import Faq from './component/otherPages/faq';
import PrivacyPollicy from './component/otherPages/privacyPollicy';
import TermsConditions from './component/otherPages/termsConditions';

import ScrollToTop from './component/srollTop';

const path = "";

function App() {
  
  return (
    <Router>
    <Suspense fallback={<div className='pageLoading'>
        <div className="spinLoader"></div>
      </div>}>
      <div className="app">
        <div className="shimmer"></div>
        <ToastContainer />
        <Nav />
        <ScrollToTop />
        <Routes>
          <Route path={`${path}/`} element={<Home />} />
          <Route path={`${path}/jobs`} element={<Jobs />} />
          <Route path={`${path}/jobDetails/:id`} element={<JobDetails />} />
          <Route path={`${path}/applyJobForm/:id`} element={<ApplyJobForm />} />
          <Route path={`${path}/login`} element={<Login />} />
          <Route path={`${path}/otp`} element={<OTP />} />
          <Route path={`${path}/register`} element={<Register />} />
          <Route path={`${path}/profile`} element={<Profile />} />
          <Route path={`${path}/savedJobs`} element={<SavedJobs />} />
          <Route path={`${path}/appliedJobs`} element={<AppliedJobs />} />
          <Route path={`${path}/countries`} element={<Countries />} />
          <Route path={`${path}/countryDetails`} element={<CountryDetails />} />
          <Route path={`${path}/about`} element={<About />} />
          <Route path={`${path}/service`} element={<Service />} />
          <Route path={`${path}/blog`} element={<Blog />} />
          <Route path={`${path}/innerBlog/:id`} element={<InnerBlog />} />
          <Route path={`${path}/testimonials`} element={<Testimonials />} />
          <Route path={`${path}/gallery`} element={<GalleryPage />} />
          <Route path={`${path}/contact`} element={<Contact />} />
          <Route path={`${path}/innerService/:id`} element={<InnerService />} />
          <Route path={`${path}/faq`} element={<Faq />} />
          <Route path={`${path}/privacyPollicy`} element={<PrivacyPollicy />} />
          <Route path={`${path}/termsConditions`} element={<TermsConditions />} />
        </Routes>
        <Footer />
      </div>
      </Suspense>
    </Router>
  );
}

export default App;
