import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import loginImg from '../../assets/images/login.jpg';
import OTPInput, { ResendOTP } from "otp-input-react";
import { toast } from 'react-toastify';

const path = "";

function OTP() {
    const [OTP, setOTP] = useState("");
    const navigate = useNavigate(); // Initialize useNavigate hook
    
    const isOTPComplete = OTP.length === 4;

    const handleResendClick = () => {
        setOTP("");  // Reset OTP value, which will disable the Verify button
        console.log("Resend clicked");
        toast.info('OTP has been resent!');
    };

    const handleVerifyClick = (e) => {
        e.preventDefault();
        if (isOTPComplete) {
            // Trigger success toast
            toast.success('OTP verified successfully!');
            // Navigate to the home page
            setTimeout(() => {
                navigate(`${path}/`);
            }, 1500); // Wait 1.5 seconds before navigating to show the toast message
        } else {
            // Trigger error toast
            toast.error('Please enter the complete OTP!');
        }
    };

    return (
        <>
            <section id="loginSection">
                <div className="container">
                    <div className="loginSectionMain">
                        <div className="loginSectionThumbnail">
                            <img src={loginImg} alt="" />
                            <div className="loginSectionThumbnailDetails">
                                <h2>Embrace new opportunities with us and start a journey that could reshape your life.</h2>
                            </div>
                        </div>
                        <div className="loginSectionDetails">
                            <h2 className='title'>Verify Phone Number</h2>
                            <p>Lorem ipsum dolor sit, amet consectetur adip isicing elit. Vitae, distinctio? Doloribus.</p>
                            <form action="">
                                <div className="formGroup">
                                    <label htmlFor="">Enter Your Phone Number</label>
                                    <div className="otpInput">
                                        <OTPInput 
                                            className="otpInputBox" 
                                            value={OTP} 
                                            onChange={setOTP} 
                                            autoFocus 
                                            OTPLength={4} 
                                            otpType="number" 
                                            disabled={false} 
                                        />
                                        <ResendOTP 
                                            className="otpBtnArea" 
                                            onResendClick={handleResendClick} 
                                        />
                                    </div>
                                </div>
                                <div className="formBtnArea">
                                    <button disabled={!isOTPComplete} onClick={handleVerifyClick}>
                                        Verify
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default OTP;
