import React from "react";
import { Link } from "react-router-dom";
import { GoChevronRight } from "react-icons/go";

const path = "";

function HomeContact() {

    return (
        <section id="homeContact">
            <div className="container">
                <div className="homeContactMain">
                    <h3 className="mainTitle">Get in Touch, We're Here to Help</h3>
                    <Link to={`${path}/contact`}>Connect Now <GoChevronRight /></Link>
                </div>
            </div>
        </section>
    );
}

export default HomeContact;
