import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { HiOutlineChevronDown } from "react-icons/hi2";
import { VscClose } from "react-icons/vsc";
import Select from "react-select";

const JobFilter = forwardRef((props, ref) => {
    const [isClearable] = useState(true);

    const [openDropdowns, setOpenDropdowns] = useState({
      keyword: false,
      position: false,
      jobTitle: false,
      location: false,
      sort: false,
      jobType: false,
    });

    useImperativeHandle(ref, () => ({
        toggleDropdown(name) {
          setOpenDropdowns((prevState) => ({
            ...prevState,
            [name]: !prevState[name],
          }));
        },
    }));

    const [selectedKeywords, setSelectedKeywords] = useState([]);
    const [selectedPositions, setSelectedPositions] = useState([]);
    const [selectedJobTitles, setSelectedJobTitles] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [selectedSort, setSelectedSort] = useState([]);
    const [selectedJobType, setSelectedJobType] = useState([]);

    const toggleDropdown = (name) => {
        setOpenDropdowns((prevState) => ({
            ...prevState,
            [name]: !prevState[name],
        }));
    };

    const keywordItem = [
        { value: "react", label: "React" },
        { value: "javaScript", label: "JavaScript" },
        { value: "css", label: "CSS" },
        { value: "html", label: "HTML" },
        { value: "nodejs", label: "Node.js" },
    ];
    
    const positions = [
        { value: "frontendDeveloper", label: "Frontend Developer" },
        { value: "backendDeveloper", label: "Backend Developer" },
        { value: "fullStackDeveloper", label: "Full Stack Developer" },
    ];
    
    const jobTitles = [
        { value: "seniorDeveloper", label: "Senior Developer" },
        { value: "juniorDeveloper", label: "Junior Developer" },
        { value: "projectManager", label: "Project Manager" },
    ];
    
    const locations = [
        { value: "newYork", label: "New York" },
        { value: "sanFrancisco", label: "San Francisco" },
        { value: "chicago", label: "Chicago" },
    ];

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 100,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#d2e2ff' : state.isFocused ? '#eee' : 'white',
            color: state.isSelected ? '#000' : 'inherit',
        }),
        multiValue: (provided) => ({
            ...provided,
            display: 'none', // Hides selected items from the dropdown
        }),
    };

    const handleSelectChange = (name, selectedOption) => {
        switch(name) {
            case 'keyword':
                setSelectedKeywords(selectedOption || []);
                break;
            case 'position':
                setSelectedPositions(selectedOption || []);
                break;
            case 'jobTitle':
                setSelectedJobTitles(selectedOption || []);
                break;
            case 'location':
                setSelectedLocations(selectedOption || []);
                break;
            case 'sort':
                setSelectedSort(selectedOption || []);
                break;
            case 'jobType':
                setSelectedJobType(selectedOption || []);
                break;
            default:
                break;
        }
    };

    const removeSelectedItem = (name, value) => {
        switch(name) {
            case 'keyword':
                setSelectedKeywords(selectedKeywords.filter(item => item.value !== value));
                break;
            case 'position':
                setSelectedPositions(selectedPositions.filter(item => item.value !== value));
                break;
            case 'jobTitle':
                setSelectedJobTitles(selectedJobTitles.filter(item => item.value !== value));
                break;
            case 'location':
                setSelectedLocations(selectedLocations.filter(item => item.value !== value));
                break;
            case 'sort':
                setSelectedSort(selectedSort.filter(item => item.value !== value));
                break;
            case 'jobType':
                setSelectedJobType(selectedJobType.filter(item => item.value !== value));
                break;
            default:
                break;
        }
    };

    const renderSelectedItems = (selectedItems, name) => (
        selectedItems.map(item => (
            <div key={item.value} className="selectedItem">
                {item.label}
                <span className="removeItemBtn" onClick={() => removeSelectedItem(name, item.value)}>
                    <VscClose />
                </span>
            </div>
        ))
    );

    const clearFilters = () => {
        setSelectedKeywords([]);
        setSelectedPositions([]);
        setSelectedJobTitles([]);
        setSelectedLocations([]);
        setSelectedSort([]);
        setSelectedJobType([]);
        setOpenDropdowns({
            keyword: false,
            position: false,
            jobTitle: false,
            location: false,
            sort: false,
            jobType: false,
        });
    };

    const closeFilterClick = () => {
        const jobWraperFilter = document.querySelector('.jobWraperFilter');
        const shimmer = document.querySelector('.shimmer');
        const body = document.querySelector('body');
    
        if (jobWraperFilter) {
          jobWraperFilter.style.left = '-110%';
        }
        if (shimmer) {
            shimmer.style.display = 'none';
        }
        if (body) {
            body.style.overflow = 'inherit';
        }
        setOpenDropdowns({
            keyword: false,
            position: false,
            jobTitle: false,
            location: false,
            sort: false,
            jobType: false,
        });
    };

    const applyFilters = () => {
        const jobWraperFilter = document.querySelector('.jobWraperFilter');
        const shimmer = document.querySelector('.shimmer');
        const body = document.querySelector('body');
    
        if (jobWraperFilter) {
          jobWraperFilter.style.left = '-110%';
        }
        if (shimmer) {
            shimmer.style.display = 'none';
        }
        if (body) {
            body.style.overflow = 'inherit';
        }
    };

    return (
        <>
            <div className="jobWraperFilter">
                <div className="jobWraperFilterFooter">
                    <div className="clearFilterBtn" onClick={clearFilters}>Clear Filter</div>
                    <div className="applyFilterBtn" >Apply Filter</div>
                    <div className="mobileApplyFilterBtn"  onClick={applyFilters}>Apply Filter</div>
                </div>
                <div className="jobWraperFilterHead">
                    <div className="jobWraperFilterHeadCol1">
                        <h2>Filters</h2>
                        {/* <p>Add preferences to get matching jobs</p> */}
                    </div>
                    <div className="jobWraperFilterHeadCol2">
                        <div className="closeFilterBtn" onClick={closeFilterClick}><VscClose /></div>
                    </div>
                </div>
                <div className="jobWraperFilterBody">
                    <div className="jobWraperFilterForm">
                        {/* Search by Keyword */}
                        <div className="formGroup">
                            <div
                                className="formGroupHead"
                                onClick={() => toggleDropdown('keyword')}
                            >
                                <div className="formGroupHeadCol1">
                                    <h3 className="formTitle">Search by keyword</h3>
                                </div>
                                <div className="formGroupHeadCol2">
                                    <div
                                        className={`filterFormDropDownBtn ${
                                            openDropdowns.keyword ? 'rotate-180' : ''
                                        }`}
                                    >
                                        <HiOutlineChevronDown />
                                    </div>
                                </div>
                            </div>
                            {openDropdowns.keyword && (
                                <div className="formGroupBody">
                                    <div className="formGroupSelect">
                                        <div className="dorpDOwnSelectedItemsAera">
                                            {renderSelectedItems(selectedKeywords, 'keyword')}
                                        </div>
                                        <Select
                                            isMulti
                                            options={keywordItem}
                                            value={selectedKeywords} // Keeps track of selected items
                                            onChange={(selected) => handleSelectChange('keyword', selected)}
                                            placeholder="Keywords"
                                            className="customSelect"
                                            isClearable={isClearable}
                                            styles={customStyles}
                                            backspaceRemovesValue={false}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Search by Position Names */}
                        <div className="formGroup">
                            <div
                                className="formGroupHead"
                                onClick={() => toggleDropdown('position')}
                            >
                                <div className="formGroupHeadCol1">
                                    <h3 className="formTitle">Search by Position Names</h3>
                                </div>
                                <div className="formGroupHeadCol2">
                                    <div
                                        className={`filterFormDropDownBtn ${
                                            openDropdowns.position ? 'rotate-180' : ''
                                        }`}
                                    >
                                        <HiOutlineChevronDown />
                                    </div>
                                </div>
                            </div>
                            {openDropdowns.position && (
                                <div className="formGroupBody">
                                    <div className="formGroupSelect">
                                        <div className="dorpDOwnSelectedItemsAera">
                                            {renderSelectedItems(selectedPositions, 'position')}
                                        </div>
                                        <Select
                                            isMulti
                                            options={positions}
                                            value={selectedPositions} // Keeps track of selected items
                                            onChange={(selected) => handleSelectChange('position', selected)}
                                            placeholder="Positions"
                                            className="customSelect"
                                            isClearable={isClearable}
                                            styles={customStyles}
                                            backspaceRemovesValue={false}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Job Title */}
                        <div className="formGroup">
                            <div
                                className="formGroupHead"
                                onClick={() => toggleDropdown('jobTitle')}
                            >
                                <div className="formGroupHeadCol1">
                                    <h3 className="formTitle">Search by Job Title</h3>
                                </div>
                                <div className="formGroupHeadCol2">
                                    <div
                                        className={`filterFormDropDownBtn ${
                                            openDropdowns.jobTitle ? 'rotate-180' : ''
                                        }`}
                                    >
                                        <HiOutlineChevronDown />
                                    </div>
                                </div>
                            </div>
                            {openDropdowns.jobTitle && (
                                <div className="formGroupBody">
                                    <div className="formGroupSelect">
                                        <div className="dorpDOwnSelectedItemsAera">
                                            {renderSelectedItems(selectedJobTitles, 'jobTitle')}
                                        </div>
                                        <Select
                                            isMulti
                                            options={jobTitles}
                                            value={selectedJobTitles} // Keeps track of selected items
                                            onChange={(selected) => handleSelectChange('jobTitle', selected)}
                                            placeholder="Job Titles"
                                            className="customSelect"
                                            isClearable={isClearable}
                                            styles={customStyles}
                                            backspaceRemovesValue={false}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Location */}
                        <div className="formGroup">
                            <div
                                className="formGroupHead"
                                onClick={() => toggleDropdown('location')}
                            >
                                <div className="formGroupHeadCol1">
                                    <h3 className="formTitle">Search by Location</h3>
                                </div>
                                <div className="formGroupHeadCol2">
                                    <div
                                        className={`filterFormDropDownBtn ${
                                            openDropdowns.location ? 'rotate-180' : ''
                                        }`}
                                    >
                                        <HiOutlineChevronDown />
                                    </div>
                                </div>
                            </div>
                            {openDropdowns.location && (
                                <div className="formGroupBody">
                                    <div className="formGroupSelect">
                                        <div className="dorpDOwnSelectedItemsAera">
                                            {renderSelectedItems(selectedLocations, 'location')}
                                        </div>
                                        <Select
                                            isMulti
                                            options={locations}
                                            value={selectedLocations} // Keeps track of selected items
                                            onChange={(selected) => handleSelectChange('location', selected)}
                                            placeholder="Locations"
                                            className="customSelect"
                                            isClearable={isClearable}
                                            styles={customStyles}
                                            backspaceRemovesValue={false}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* job type */}
                        <div className="formGroup">
                            <div
                                className="formGroupHead"
                                onClick={() => toggleDropdown('jobType')}
                            >
                                <div className="formGroupHeadCol1">
                                    <h3 className="formTitle">Job Type</h3>
                                </div>
                                <div className="formGroupHeadCol2">
                                    <div
                                        className={`filterFormDropDownBtn ${
                                            openDropdowns.jobType ? 'rotate-180' : ''
                                        }`}
                                    >
                                        <HiOutlineChevronDown />
                                    </div>
                                </div>
                            </div>
                            {openDropdowns.jobType && (
                                <div className="formGroupBody">
                                    <div className="formGroupCheckboxArea">
                                        <ul>
                                            <li>
                                                <input type="checkbox" id='fullTime' />
                                                <label htmlFor="fullTime">
                                                    <div className="checkboxIcon"></div>
                                                    <div className="checkboxText">Full-Time</div>
                                                </label>
                                            </li>
                                            <li>
                                                <input type="checkbox" id='partTime' />
                                                <label htmlFor="partTime">
                                                    <div className="checkboxIcon"></div>
                                                    <div className="checkboxText">Part-Time</div>
                                                </label>
                                            </li>
                                            <li>
                                                <input type="checkbox" id='temporary' />
                                                <label htmlFor="temporary">
                                                    <div className="checkboxIcon"></div>
                                                    <div className="checkboxText">Temporary</div>
                                                </label>
                                            </li>
                                            <li>
                                                <input type="checkbox" id='intern' />
                                                <label htmlFor="intern">
                                                    <div className="checkboxIcon"></div>
                                                    <div className="checkboxText">Intern</div>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Sort */}
                        <div className="formGroup">
                            <div
                                className="formGroupHead"
                                onClick={() => toggleDropdown('sort')}
                            >
                                <div className="formGroupHeadCol1">
                                    <h3 className="formTitle">Sort By</h3>
                                </div>
                                <div className="formGroupHeadCol2">
                                    <div
                                        className={`filterFormDropDownBtn ${
                                            openDropdowns.sort ? 'rotate-180' : ''
                                        }`}
                                    >
                                        <HiOutlineChevronDown />
                                    </div>
                                </div>
                            </div>
                            {openDropdowns.sort && (
                                <div className="formGroupBody">
                                    <div className="formGroupRadioArea">
                                        <ul>
                                            <li>
                                                <input type="radio" id='relevance' name='sort' />
                                                <label htmlFor="relevance">
                                                    <div className="radioIcon"></div>
                                                    <div className="radioText">Relevance</div>
                                                </label>
                                            </li>
                                            <li>
                                                <input type="radio" id='date' name='sort' />
                                                <label htmlFor="date">
                                                    <div className="radioIcon"></div>
                                                    <div className="radioText">Date</div>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
})

export default JobFilter;
