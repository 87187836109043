import React, { useState, useEffect } from "react";
import { useNavigate,useParams } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import { Link,useLocation } from 'react-router-dom';
import HomeContact from "../home/homeContact";
import { MdArrowBack } from "react-icons/md";
import { GoLocation } from "react-icons/go";
import { IoHeartOutline, IoHeart } from "react-icons/io5";
import { PiMoneyWavy, PiBriefcaseLight } from "react-icons/pi";
import BackBtn from "../settings/backBtn";
import api, { baseURL } from '../axiosInterceptor/axiosInterceptorC';
import axios from 'axios';
import { toast } from 'react-toastify';

import companyLogo1 from '../../assets/images/compnay/logoIcon1.png'
import companyLogo2 from '../../assets/images/compnay/img1.webp'
import companyLogo3 from '../../assets/images/compnay/img2.svg'
import companyLogo4 from '../../assets/images/compnay/img3.png'
import companyLogo5 from '../../assets/images/compnay/img4.webp'
import companyLogo6 from '../../assets/images/compnay/img5.webp'
import companyLogo7 from '../../assets/images/logo.png'

const path = "";

const JobBox = lazy(() => import("../home/jobBox"));


function JobDetails() {
    const { id } = useParams();
    const [saved, setSaved] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const [items, setItems] = useState([]);
    const[languages,setLanguages]=useState([]);
    const[weeklyoffs,setweeklyoffs]=useState([]);
    const[tags,settags]=useState([]);


    const navigate = useNavigate();

    const handleSaveClick = () => {
        setSaved(prevSaved => !prevSaved);
        // toast(saved ? "Job removed from saved jobs" : "Job saved successfully!");
        toast(saved ? "Job removed from saved jobs" : "Job saved successfully!", {
            position: "top-right",
            autoClose: 2000, // 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            // progress: undefined,
            // theme: "colored",
        });
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleBackClick = () => {
        navigate(-1); // Go back to the previous page
    };

    
    // const location = useLocation(); 
    // const queryParams = new URLSearchParams(location.search);
    const jobId = id;
    const [jobData, setJobData] = useState({
        job_details: {},
        company_details: {},
        days_ago:'',
      });

      useEffect(() => {
        axios.get(`${baseURL}/fetch_job_details?id=${jobId}`)
          .then(response => {
            console.log(response.data)
            setJobData(response.data);
          })
          .catch(error => {
            console.error('Error fetching job data:', error);
          });
      }, []);

      const { job_details, company_details,days_ago } = jobData;

      const companyLogoPath = 'https://danielsjobfinder.com/demo3/public/';
      const companyLogoUrl = company_details.company_logo 
    ? `${companyLogoPath}${company_details.company_logo}` 
    : '';

    //qualification datas
    useEffect(() => {
        axios.get(`${baseURL}/fetch_qualification_datas?id=${jobId}`) // Replace with your API URL
          .then(response => {
            console.log(response.data)
            setItems(response.data);
          })
          .catch(error => {
            console.error('Error fetching items:', error);
          });
      }, []); 

      //languages datas
    useEffect(() => {
        axios.get(`${baseURL}/fetch_languages_datas?id=${jobId}`) // Replace with your API URL
          .then(response => {
            console.log(response.data)
            setLanguages(response.data);
          })
          .catch(error => {
            console.error('Error fetching items:', error);
          });
      }, []); 

      //weekly off datas
    useEffect(() => {
        axios.get(`${baseURL}/fetch_weeklyoff_datas?id=${jobId}`) // Replace with your API URL
          .then(response => {
            console.log(response.data)
            setweeklyoffs(response.data);
          })
          .catch(error => {
            console.error('Error fetching items:', error);
          });
      }, []);

            //tags datas
    useEffect(() => {
        axios.get(`${baseURL}/fetch_tags_datas?id=${jobId}`) // Replace with your API URL
          .then(response => {
            console.log(response.data)
            settags(response.data);
          })
          .catch(error => {
            console.error('Error fetching items:', error);
          });
      }, []);

      const [jobData1, setJobData1] = useState([]);
      //fetch job details
      useEffect(() => {
        axios.get(`${baseURL}/fetch_job_details_all`)
          .then(response => {
            console.log(response.data)
            setJobData1(response.data);
          })
          .catch(error => {
            console.error('Error fetching job data:', error);
          });
      }, []);
      //const companyLogoPath = 'http://127.0.0.1:8000/';
  return (
    <>
        <section id="jobDetails">
            <div className={`jobApplyHeadStrip ${isScrolled ? 'jobApplyHeadStripActive' : ''}`}>
                <div className="container">
                    <div className="jobApplyHeadStripMain">
                        <div className="jobApplyHeadStripCol1">
                            <div className="jobApplyHeadStripProfile">
                                <div className="jobApplyHeadStripProfileIcon">
                                    <img src={companyLogo7} alt="" />
                                </div>
                                <div className="jobApplyHeadStripProfileTitle">
                                    <div className="jobCompany">{company_details.company_name}</div>
                                    <div className="jobTitleName">{job_details.job_title}</div>
                                </div>
                            </div>
                        </div>
                        <div className="jobApplyHeadStripCol2">
                            <Link to={`${path}/applyJobForm`} className="jobApplyHeadStripApplyBtn">Apply Job</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="jobDetailsMain">
                    <BackBtn />
                    <div className="jobDetailsCol1">
                        <div className="jobDetailsBanner">
                            <div className="jobDetailsBannerHead">
                                <div className="jobDetailsBannerHeadCol1">
                                    <div className="jobDetailsBannerHeadProfile">
                                        <div className="jobDetailsBannerHeadProfileIcon">
                                            <img src={companyLogo7} alt="" />
                                        </div>
                                        <div className="jobDetailsBannerHeadProfileTitle">
                                            <div className="jobCompany">{company_details.company_name}</div>
                                            <div className="jobTitleName">{job_details.job_title}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="jobDetailsBannerHeadCol2">
                                    <div className="jobDetailsBannerHeadSaveBtn" onClick={handleSaveClick}>
                                        {saved ? <IoHeart style={{ color: 'red' }} /> : <IoHeartOutline />}
                                    </div>
                                </div>
                            </div>
                            <div className="jobDetailsBannerBody">
                                <ul>
                                    <li>
                                        <div className="icon">
                                            <PiBriefcaseLight />
                                        </div>
                                        <p>{job_details.experience_range} Yrs</p>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <PiMoneyWavy />
                                        </div>
                                        <p>{job_details.from_amount} - {job_details.to_amount} {job_details.salary_currency}</p>
                                    </li>
                                    <li>
                                        <p>{job_details.no_openings} Opening</p>
                                    </li>
                                    <li className="location">
                                        <div className="icon">
                                            <GoLocation />
                                        </div>
                                        <p>{job_details.location_val}</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="jobDetailsBannerFooter">
                                <div className="jobDetailsBannerFooterCol1">
                                    <div className="postedDate">
                                        <p>{days_ago}</p>
                                    </div>
                                </div>
                                <div className="jobDetailsBannerFooterCol2">
                                    <Link to={`${path}/applyJobForm`} className="jobDetailsBannerApplyBtn">Apply Job</Link>
                                </div>
                            </div>
                        </div>
                        <div className="jobDetailsArea">
                            <div className="jobDetailsAreaBox">
                                <h2 className="subTitle">Job Description</h2>
                                <div dangerouslySetInnerHTML={{ __html: job_details.job_description }} />
                            </div>
                            <div className="jobDetailsAreaBox">
                                <h2 className="subTitle">Qualifications</h2>
                                <ul>
                                {items.map(item => (
                                <li key={item.id}>{item.qualifications}</li>
                                ))}
                                </ul>
                            </div>
                            <div className="jobDetailsAreaBox">
                                <h2 className="subTitle">Languages</h2>
                                <ul>
                                {languages.map(item => (
                                <li key={item.id}>{item.language_val}</li>
                                ))}
                                </ul>
                            </div>
                            <div className="jobDetailsAreaBox">
                                <h2 className="subTitle">Tags</h2>
                                <ul>
                                {tags.map(item => (
                                <li key={item.id}>{item.tag_val}</li>
                                ))}
                                </ul>
                            </div>
                        </div>
                        <div className="jobDetailsArea" style={{ display: 'none' }}>
                            <div className="jobDetailsAreaBox">
                                <h2 className="subTitle">About company</h2>
                                <div dangerouslySetInnerHTML={{ __html: company_details.about_company }} />
                            </div>
                            <div className="jobDetailsAreaBox">
                                <h2 className="subTitle">Company Info</h2>
                                <div className="companyDetails">
                                    <div className="companyDetailsBox">
                                        <div className="companyDetailsBoxCol1">
                                            <h3>Address</h3>
                                            <span>:</span>
                                        </div>
                                        <div className="companyDetailsBoxCol2">
                                            <p>{company_details.adress_val}</p>
                                        </div>
                                    </div>
                                    <div className="companyDetailsBox">
                                        <div className="companyDetailsBoxCol1">
                                            <h3>Website</h3>
                                            <span>:</span>
                                        </div>
                                        <div className="companyDetailsBoxCol2">
                                            <Link to={`${company_details.website_val}`} target="_blank">{company_details.website_val}</Link>
                                        </div>
                                    </div>
                                    <div className="companyDetailsBox">
                                        <div className="companyDetailsBoxCol1">
                                            <h3>Phone</h3>
                                            <span>:</span>
                                        </div>
                                        <div className="companyDetailsBoxCol2">
                                            <Link to={`tel:+91${company_details.phone_number}`}>+91 {company_details.phone_number}</Link>
                                        </div>
                                    </div>
                                    <div className="companyDetailsBox">
                                        <div className="companyDetailsBoxCol1">
                                            <h3>Email</h3>
                                            <span>:</span>
                                        </div>
                                        <div className="companyDetailsBoxCol2">
                                            <Link to="mailto:info@esightsolutions.com">info@esightsolutions.com</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="jobDetailsCol2">
                        <h2 className="subTitle">Jobs you might be interested in</h2>
                        <div className="jobDetailsCol2JobList">
                                {jobData1.length > 0 ? (
                                        jobData1.map((job, index) => (
                        
                        <Suspense key={index} fallback={<div>Loading...</div>}>
                        <JobBox
                        jobLink={`${path}/jobDetails/${job.id}`}  // Use job ID or some unique identifier
                        jobCompanyImg={companyLogo7}  // Pass the image URL
                        jobCompanyName={job.company_name}  // Assuming the API returns 'company_name'
                        jobTitle={job.job_title}  // Assuming the API returns 'job_title'
                        jobLocation={job.location_val}  // Assuming the API returns 'location'
                        jobExperiance={job.experience_range}  // Adjust according to your data structure
                        jobSalary={`${job.from_amount} - ${job.to_amount}${job.salary_currency}`}  // Assuming salary range is provided
                        jobOpening={job.no_openings}  // Number of openings
                        jobPostedDate={job.days_ago}  // Use 'days_ago' from the backend
                        applyJobLink={`${path}/applyJobForm/${job.id}`}
                        />
                    </Suspense>


                    ))
                ) : (
                    <div className="no-data">No data found</div>
                )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <HomeContact />
    </>
  );
}

export default JobDetails;
