
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ImGoogle } from "react-icons/im";
import loginImg from '../../assets/images/login.jpg'
import { toast } from 'react-toastify';

const path = "";

function Login() {

    const navigate = useNavigate();

    const handleGetOTP = (e) => {
        e.preventDefault();
        // Here you can add logic to validate phone number or initiate OTP request.
        toast.info('OTP has been sent to your phone');
        navigate(`${path}/otp`);
    };
    
  return (
    <>
        <section id="loginSection">
            <div className="container">
                <div className="loginSectionMain">
                    <div className="loginSectionThumbnail">
                        <img src={loginImg} alt="" />
                        <div className="loginSectionThumbnailDetails">
                            <h2>Embrace new opportunities with us and start a journey that could reshape your life.</h2>
                        </div>
                    </div>
                    <div className="loginSectionDetails">
                        <h2 className='title'>Let's join with us</h2>
                        <p>Lorem ipsum dolor sit, amet consectetur adip isicing elit. Vitae, distinctio? Doloribus.</p>
                        <form action="">
                            <div className="formGroup">
                                <label htmlFor="">Enter Your Phone Number</label>
                                <input type="number" />
                                <div className="error">This phone number not registered</div>
                            </div>
                            <div className="formBtnArea">
                                <button onClick={handleGetOTP}>Get OTP</button>
                            </div>
                            <div className="orText">Or</div>
                            <Link className="signingGoogle"><ImGoogle /> Sign in with google</Link>
                        </form>
                        <div className="logingFooterStrip">
                            <p>I don't have an account.</p>
                            <Link to={`${path}/register`}>Register Now</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default Login;
