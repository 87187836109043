import React from "react";
import { Link } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import JobBanner from "./jobBanner";
import HomeContact from "../home/homeContact";
import { RiSearchLine } from "react-icons/ri";

import companyLogo1 from '../../assets/images/compnay/logoIcon1.png'
import companyLogo2 from '../../assets/images/compnay/img1.webp'
import companyLogo3 from '../../assets/images/compnay/img2.svg'
import companyLogo4 from '../../assets/images/compnay/img3.png'
import companyLogo5 from '../../assets/images/compnay/img4.webp'
import companyLogo6 from '../../assets/images/compnay/img5.webp'

const path = "";

const JobBox = lazy(() => import("../home/jobBox"));


function AppliedJobs() {
    
  return (
    <>
        <JobBanner jobBannerTitle="Applied Jobs" />
        <section id='savedJobs' className="appliedJob">
            <div className="container">
                <div className="savedJobsMain">
                    <Suspense>
                        <JobBox jobLink={`${path}/jobDetails`} jobCompanyImg={companyLogo1} jobCompanyName="Esight Business Solutions Pvt Ltd" jobTitle="Web Developer" jobLocation="Angamaly, Kerala" jobExperiance="2 - 5 Yrs" jobSalary="30k - 50k" jobOpening="2" jobPostedDate="1" />
                    </Suspense>
                    <Suspense>
                        <JobBox jobLink={`${path}/jobDetails`} jobCompanyImg={companyLogo2} jobCompanyName="Google Pvt Ltd" jobTitle="Google Cloud Consulting" jobLocation="UK" jobExperiance="1 - 2 Yrs" jobSalary="1 L - 1.5 L" jobOpening="1" jobPostedDate="3" />
                    </Suspense>
                    <Suspense>
                        <JobBox jobLink={`${path}/jobDetails`} jobCompanyImg={companyLogo3} jobCompanyName="Amazon" jobTitle="Truck Driver" jobLocation="Kerala, India" jobExperiance="5 - 8 Yrs" jobSalary="1 L - 2 L" jobOpening="3" jobPostedDate="2" />
                    </Suspense>
                    <Suspense>
                        <JobBox jobLink={`${path}/jobDetails`} jobCompanyImg={companyLogo4} jobCompanyName="Lulu Group International" jobTitle="Assistant Manager" jobLocation="Dubai" jobExperiance="1 - 2 Yrs" jobSalary="50k - 75k" jobOpening="1" jobPostedDate="4" />
                    </Suspense>
                    <Suspense>
                        <JobBox jobLink={`${path}/jobDetails`} jobCompanyImg={companyLogo5} jobCompanyName="Bayerische Motoren Werke AG" jobTitle="Sales Executive" jobLocation="USA" jobExperiance="3 - 4 Yrs" jobSalary="2 L - 3 L" jobOpening="5" jobPostedDate="1" />
                    </Suspense>
                    <Suspense>
                        <JobBox jobLink={`${path}/jobDetails`} jobCompanyImg={companyLogo6} jobCompanyName="X Corp" jobTitle="Prompt Engineer" jobLocation="California, US" jobExperiance="1 - 2 Yrs" jobSalary="75k - 1 L" jobOpening="10" jobPostedDate="1" />
                    </Suspense>
                    <Suspense>
                        <JobBox jobLink={`${path}/jobDetails`} jobCompanyImg={companyLogo1} jobCompanyName="Esight Business Solutions Pvt Ltd" jobTitle="Web Developer" jobLocation="Angamaly, Kerala" jobExperiance="2 - 5 Yrs" jobSalary="30k - 50k" jobOpening="2" jobPostedDate="2" />
                    </Suspense>
                    <Suspense>
                        <JobBox jobLink={`${path}/jobDetails`} jobCompanyImg={companyLogo2} jobCompanyName="Google Pvt Ltd" jobTitle="Google Cloud Consulting" jobLocation="UK" jobExperiance="1 - 2 Yrs" jobSalary="1 L - 1.5 L" jobOpening="1" jobPostedDate="5" />
                    </Suspense>
                    <Suspense>
                        <JobBox jobLink={`${path}/jobDetails`} jobCompanyImg={companyLogo3} jobCompanyName="Amazon" jobTitle="Truck Driver" jobLocation="Kerala, India" jobExperiance="5 - 8 Yrs" jobSalary="1 L - 2 L" jobOpening="3" jobPostedDate="3" />
                    </Suspense>
                    <Suspense>
                        <JobBox jobLink={`${path}/jobDetails`} jobCompanyImg={companyLogo4} jobCompanyName="Lulu Group International" jobTitle="Assistant Manager" jobLocation="Dubai" jobExperiance="1 - 2 Yrs" jobSalary="50k - 75k" jobOpening="1" jobPostedDate="2" />
                    </Suspense>
                    <Suspense>
                        <JobBox jobLink={`${path}/jobDetails`} jobCompanyImg={companyLogo5} jobCompanyName="Bayerische Motoren Werke AG" jobTitle="Sales Executive" jobLocation="USA" jobExperiance="3 - 4 Yrs" jobSalary="2 L - 3 L" jobOpening="5" jobPostedDate="4" />
                    </Suspense>
                    <Suspense>
                        <JobBox jobLink={`${path}/jobDetails`} jobCompanyImg={companyLogo6} jobCompanyName="X Corp" jobTitle="Prompt Engineer" jobLocation="California, US" jobExperiance="1 - 2 Yrs" jobSalary="75k - 1 L" jobOpening="10" jobPostedDate="1" />
                    </Suspense>

                    <div className="jobWraperLoadMore">
                    <div className="jobWraperLoadMoreBtn">Show More</div>
                    </div>

                    <div className="noDataFound">
                    <div className="noDataFoundIcon">
                        <RiSearchLine />
                    </div>
                    <div className="noDataFoundDetails">
                        <h3>No results</h3>
                        <p>No results found for applied jobs. Please visit jobs page.</p>
                        <Link to={`${path}/jobs`} className="noDataFoundClearFIlterBtn">Go to jobs</Link>
                    </div>
                    </div>
                </div>
            </div>
        </section>
        <HomeContact />
    </>
  );
}

export default AppliedJobs;
