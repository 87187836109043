import React from "react";
import { Suspense, lazy } from 'react';
// import { Link } from 'react-router-dom';

import JobCatImg1 from '../../assets/images/jobCatagory/img1.png'
import JobCatImg2 from '../../assets/images/jobCatagory/img2.png'

const path = "";

const JobCatagory = lazy(() => import("./jobCatagory"));

function HomeJobCatagory() {

    return (
        <section id="homeJobCatagory">
            <div className="container">
                <div className="titleHead">
                    <h2 className="title">Popular Job Categories</h2>
                </div>
                <div className="homeJobCatagoryMain">
                    <Suspense>
                        <JobCatagory jobCatagoryLink={`${path}/jobs`} jobCatThumbnail={JobCatImg1} jobCatTitle="UI/UX DESIGNER" jobCatPost="20" />
                    </Suspense>
                    <Suspense>
                        <JobCatagory jobCatagoryLink={`${path}/jobs`} jobCatThumbnail={JobCatImg2} jobCatTitle="Web Developer" jobCatPost="10" />
                    </Suspense>
                    <Suspense>
                        <JobCatagory jobCatagoryLink={`${path}/jobs`} jobCatThumbnail={JobCatImg2} jobCatTitle="Full Time" jobCatPost="10" />
                    </Suspense>
                    <Suspense>
                        <JobCatagory jobCatagoryLink={`${path}/jobs`} jobCatThumbnail={JobCatImg2} jobCatTitle="Part Time" jobCatPost="10" />
                    </Suspense>
                    <Suspense>
                        <JobCatagory jobCatagoryLink={`${path}/jobs`} jobCatThumbnail={JobCatImg2} jobCatTitle="Temporary" jobCatPost="10" />
                    </Suspense>
                    <Suspense>
                        <JobCatagory jobCatagoryLink={`${path}/jobs`} jobCatThumbnail={JobCatImg2} jobCatTitle="Intern" jobCatPost="10" />
                    </Suspense>
                    <Suspense>
                        <JobCatagory jobCatagoryLink={`${path}/jobs`} jobCatThumbnail={JobCatImg2} jobCatTitle="White Collar Jobs" jobCatPost="10" />
                    </Suspense>
                    <Suspense>
                        <JobCatagory jobCatagoryLink={`${path}/jobs`} jobCatThumbnail={JobCatImg2} jobCatTitle="Blue Collar Jobs" jobCatPost="10" />
                    </Suspense>
                </div>
            </div>
        </section>
    );
}

export default HomeJobCatagory;
