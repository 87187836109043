import React, { useState, useEffect } from "react";
import HomeContact from "../home/homeContact";
import AvatarImg from '../../assets/images/avatar.png'; // Fallback avatar
import axios from 'axios';
import api, { baseURL } from '../axiosInterceptor/axiosInterceptorC'; // Axios interceptor
const HomeTestimonialsBox = React.lazy(() => import("../home/homeTestimonialsBox"));

function Testimonials() {
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch testimonials from API
  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await api.get(`${baseURL}/testimonials`); // Replace with actual API endpoint
        setTestimonials(response.data.data);
        console.log(response.data.data)
      } catch (error) {
        setError('Failed to fetch testimonials');
      } finally {
        setLoading(false);
      }
    };

    fetchTestimonials();
  }, []);

  if (loading) {
    return <>
            <section id="testimonialsSection">
              <div className="container">
                <div className="testimonialsSectionTitle">
                  <h2 className="title">Testimonials</h2>
                  <h3 className="mainTitle">Real Stories of Success and Satisfaction</h3>
                </div>
                <div className="testimonialsSectionList">
                  
                    <React.Suspense >
                      <HomeTestimonialsBox
                        homeTestimonialsContent={'http://127.0.0.1:8000/'}
                        homeTestimonialsImg={'http://127.0.0.1:8000/'} // Use fallback avatar if imgUrl is unavailable
                        homeTestimonialsName={'http://127.0.0.1:8000/'}
                        homeTestimonialsDesignation={'http://127.0.0.1:8000/'}
                      />
                    </React.Suspense>
                    <React.Suspense >
                      <HomeTestimonialsBox
                        homeTestimonialsContent={'http://127.0.0.1:8000/'}
                        homeTestimonialsImg={'http://127.0.0.1:8000/'} // Use fallback avatar if imgUrl is unavailable
                        homeTestimonialsName={'http://127.0.0.1:8000/'}
                        homeTestimonialsDesignation={'http://127.0.0.1:8000/'}
                      />
                    </React.Suspense>
                    <React.Suspense >
                      <HomeTestimonialsBox
                        homeTestimonialsContent={'http://127.0.0.1:8000/'}
                        homeTestimonialsImg={'http://127.0.0.1:8000/'} // Use fallback avatar if imgUrl is unavailable
                        homeTestimonialsName={'http://127.0.0.1:8000/'}
                        homeTestimonialsDesignation={'http://127.0.0.1:8000/'}
                      />
                    </React.Suspense>
                    <React.Suspense >
                      <HomeTestimonialsBox
                        homeTestimonialsContent={'http://127.0.0.1:8000/'}
                        homeTestimonialsImg={'http://127.0.0.1:8000/'} // Use fallback avatar if imgUrl is unavailable
                        homeTestimonialsName={'http://127.0.0.1:8000/'}
                        homeTestimonialsDesignation={'http://127.0.0.1:8000/'}
                      />
                    </React.Suspense>
                  
                </div>
              </div>
            </section>

            <HomeContact />
          </>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <section id="testimonialsSection">
        <div className="container">
          <div className="testimonialsSectionTitle">
            <h2 className="title">Testimonials</h2>
            <h3 className="mainTitle">Real Stories of Success and Satisfaction</h3>
          </div>
          <div className="testimonialsSectionList">
            {testimonials.map((testimonial, index) => (
              <React.Suspense key={index}>
                <HomeTestimonialsBox
                  homeTestimonialsContent={testimonial.testiomnial_desc}
                  homeTestimonialsImg={testimonial.imgUrl || AvatarImg} // Use fallback avatar if imgUrl is unavailable
                  homeTestimonialsName={testimonial.person_name}
                  homeTestimonialsDesignation={testimonial.designation}
                />
              </React.Suspense>
            ))}
          </div>
        </div>
      </section>

      <HomeContact />
    </>
  );
}

export default Testimonials;
