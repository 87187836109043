import React, { useEffect, useState, useTransition, useRef } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { RiLoginCircleLine } from "react-icons/ri";
import { FaUserAlt } from "react-icons/fa";
import { IoHeartOutline } from "react-icons/io5";
import { LuSearch } from "react-icons/lu";
import { IoMdNotificationsOutline } from "react-icons/io";
import { FiSearch } from "react-icons/fi";

import { LuChevronRight } from "react-icons/lu";
import { GrClose } from "react-icons/gr";
import { TbHome2, TbBookmarks, TbBriefcase, TbDeviceIpadHorizontalCheck, TbMessage, TbBuilding, TbHeartHandshake, TbMessage2Search, TbMessage2Heart, TbLogout } from "react-icons/tb";
import { IoMdGlobe } from "react-icons/io";
import { BsPassport } from "react-icons/bs";
import { BiImages, BiSupport } from "react-icons/bi";

import Select from "react-select";

import logoImg from '../../assets/images/logo.png'

const path = "";

const designations = [
    { value: "developer", label: "Developer" },
    { value: "designer", label: "Designer" },
    { value: "manager", label: "Manager" },
    // Add more options here
];

const locations = [
    { value: "new-york", label: "New York" },
    { value: "san-francisco", label: "San Francisco" },
    { value: "los-angeles", label: "Los Angeles" },
    // Add more options here
];

const experiences = [
    { value: "0-1", label: "0-1 Yrs" },
    { value: "2-5", label: "2-5 Yrs" },
    { value: "6-10", label: "6-10 Yrs" },
    // Add more options here
];

function Nav() {

    const [isClearable] = useState(true);

    const location = useLocation();
    const sideMenu = document.querySelector('.sidemenu');
    const shimmer = document.querySelector('.shimmer');
    const body = document.querySelector('body');
    const navSearchStrip = document.querySelector('.navSearchStrip');
    const [isSideActive,setIsSideActive] = useState(false);
    const [isSearchActive,setIsSearchActive] = useState(false);
    const [isLoginPopupVisible, setIsLoginPopupVisible] = useState(false);
    const inputRef = useRef(null);

    // useEffect(() => {
    //   const shimmer = document.querySelector('.shimmer'); // Access shimmer inside useEffect
    //   if (shimmer) { // Check if shimmer exists
    //       const timer = setTimeout(() => {
    //           setIsLoginPopupVisible(true);
    //           shimmer.style.display = 'block';
    //       }, 5000); // Show loginPopup after 5 seconds

    //       return () => clearTimeout(timer); // Cleanup the timer
    //   }
    // }, []);
    
    const closeloginPopup = () => {
      setIsLoginPopupVisible(false);
      if (shimmer) {
          shimmer.style.display = 'none';
      }
  }

    const isActiveJob = () => {
        return location.pathname.includes(`/jobs`) || location.pathname.includes(`/jobDetails`);
    };
    const isCountriesJob = () => {
        return location.pathname.includes(`/countries`) || location.pathname.includes(`/countryDetails`);
    };
    const isBlogJob = () => {
        return location.pathname.includes(`/blog`) || location.pathname.includes(`/innerBlog`);
    };
    const isServiceJob = () => {
        return location.pathname.includes(`/service`) || location.pathname.includes(`/innerService`);
    };

    const [isPending, startTransition] = useTransition();
    const [data, setData] = useState(null);
  
    useEffect(() => {
      startTransition(() => {
        // Simulating an async operation like fetching data
        setTimeout(() => {
          setData({ loaded: true });
        }, 1000);
      });
    }, []);
  
    if (isPending || !data) {
      return <div className='pageLoading'>
        <div className="spinLoader"></div>
      </div>;
    }

    const navBarClik = () => {
        setIsSideActive(!isSideActive)
        if (shimmer) {
            shimmer.style.display = 'block';
        }
        if (body) {
            body.style.overflow = 'hidden';
        }
    }

    const closeSidemenuClick = () => {
        setIsSideActive(!isSideActive)
        if (shimmer) {
            shimmer.style.display = 'none';
        }
        if (body) {
            body.style.overflow = 'inherit';
        }
    }

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 100,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#d2e2ff' : state.isFocused ? '#eee' : 'white',
            color: state.isSelected ? '#000' : 'inherit',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#000',
        }),
    };

    const navSearchClick = () => {
      setIsSearchActive(!isSearchActive)
        if (shimmer) {
            shimmer.style.display = 'block';
        }
        if (body) {
            body.style.overflow = 'hidden';
        }
        if (inputRef.current) {
          inputRef.current.focus();
        }
    }

    const closeNavSearchStripClick = () => {
      setIsSearchActive(!isSearchActive)
      if (shimmer) {
          shimmer.style.display = 'none';
      }
      if (body) {
          body.style.overflow = 'inherit';
      }
  }
    
  return (
    <>
    <nav>
      <div className="container">
        <div className="navMain">
          <div className="navCol1">
            <Link to={`${path}/`} className="navLogo">
              <img src={logoImg} alt="" />
            </Link>
            <div className="navLink">
              <ul>
                <li>
                  <NavLink to={`${path}/`} end className={({ isActive }) => (isActive ? 'navLinkActive' : '')}>Home</NavLink>
                </li>
                <li>
                  <NavLink to={`${path}/jobs`} className={() => (isActiveJob() ? 'navLinkActive' : '')}>Jobs</NavLink>
                </li>
                {/* <li>
                  <NavLink to={`${path}/countries`} className={({ isActive }) => (isActive ? 'navLinkActive' : '')}>Countries</NavLink>
                </li>
                <li>
                  <NavLink to={`${path}/about`} className={({ isActive }) => (isActive ? 'navLinkActive' : '')}>About</NavLink>
                </li>
                <li>
                  <NavLink to={`${path}/service`} className={({ isActive }) => (isActive ? 'navLinkActive' : '')}>Service</NavLink>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="navCol2">
            <div className="navSearch">
              <div className="navSearchBox" onClick={navSearchClick}>
                <p>Search job...</p>
                <LuSearch />
              </div>
            </div>
            {/* <div className="navBtnArea">
              <Link className="navBtnAreaBox">
                <IoMdNotificationsOutline />
                <div className="notificationActiveIcon"></div>
                <div className="navDropDown">
                  <ul>
                    <li>
                      <Link to={`${path}/jobs`}>
                        <h2>Recommended jobs for you</h2>
                        <p>Web Developer</p>
                      </Link>
                    </li>
                    <li>
                      <Link to={`${path}/jobs`}>
                        <h2>Recommended jobs for you</h2>
                        <p>UI Designer</p>
                      </Link>
                    </li>
                  </ul>
                </div>
              </Link>
            </div> */}
            <div className="navLogin">
              <div className='navProfileBtn' onClick={navBarClik}>
                <div className="navProfileNavBar">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className="navProfileIcon">
                    <FaUserAlt />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div className={`navSearchStrip ${isSearchActive && 'navSearchStripActive'}`}>
      <div className="closeNavSearchStrip" onClick={closeNavSearchStripClick}>
        <GrClose />
      </div>
      <div className="container">
        <div className="navSearchStripMain">
            <h2 className='title'>Search</h2>
            <form action="">
                <div className="formGroupBox">
                    <div className="formGroup">
                        <Select
                            options={designations}
                            placeholder="Designation"
                            className="customSelect"
                            isClearable={isClearable}
                            styles={customStyles}
                        />
                    </div>
                    <div className="formGroup">
                        <Select
                            options={locations}
                            placeholder="Location"
                            isSearchable={true}
                            className="customSelect"
                            isClearable={isClearable}
                            styles={customStyles}
                        />
                    </div>
                    <div className="formGroup">
                        <Select
                            options={experiences}
                            placeholder="Experience"
                            className="customSelect"
                            isClearable={isClearable}
                            styles={customStyles}
                        />
                    </div>
                </div>

                {/* only mobile search */}
                <div className="mobileSearchInput">
                  <input ref={inputRef} type="search" placeholder='Search Jobs, Location etc....' />
                </div>
                {/* only mobile search */}

                <div className="formBtnArea">
                    <button className="formSbtBtn">
                        <FiSearch /> Search
                    </button>
                </div>
            </form>
        </div>
      </div>
    </div>

    <aside className={`sidemenu ${isSideActive && 'sidemenuActive'}`}>
        <div className="closeSidemenu" onClick={closeSidemenuClick}>
            <GrClose />
        </div>
        <div className="sidemenuHead" style={{display: 'none'}}>
            <div className="sidemenuHeadLogin">
              <Link to={`${path}/login`} className='sidemenuLoginBtn'>Login <RiLoginCircleLine /></Link>
            </div>
            <div className="sidemenuHeadProfile">
              <div className="sidemenuHeadCol1">
                  <div className="sidemenuProfileIcon">
                      <FaUserAlt />
                  </div>
              </div>
              <div className="sidemenuHeadCol2">
                  <div className="sidemenuProfileName">Afsal Nazeer</div>
                  <div className="sidemenuPhone">+91 98765 43210</div>
                  <Link to={`${path}/profile`} className='sidemenuProfileViewBtn' onClick={closeSidemenuClick}>View & update profile <LuChevronRight /></Link>
              </div>
            </div>
        </div>
        <div className="sidemenuBody" style={{marginTop: '60px'}}>
            <ul>
                <li>
                    <NavLink to={`${path}/`} end className={({ isActive }) => (isActive ? 'sidemenuLinkActive' : '')} onClick={closeSidemenuClick}><TbHome2 /> Home</NavLink>
                </li>
                {/* <li>
                    <NavLink to={`${path}/savedJobs`} className={({ isActive }) => (isActive ? 'sidemenuLinkActive' : '')} onClick={closeSidemenuClick}><TbBookmarks /> Saved Jobs</NavLink>
                </li>
                <li>
                    <NavLink to={`${path}/appliedJobs`} className={({ isActive }) => (isActive ? 'sidemenuLinkActive' : '')} onClick={closeSidemenuClick}><TbBriefcase /> Applied Jobs</NavLink>
                </li> */}
                <li>
                  <NavLink to={`${path}/jobs`} className={() => (isActiveJob() ? 'sidemenuLinkActive' : '')} onClick={closeSidemenuClick}><TbDeviceIpadHorizontalCheck /> Jobs</NavLink>
                </li>
            </ul>
            <ul>
                {/* <li>
                  <NavLink to={`${path}/countries`} className={() => (isCountriesJob() ? 'sidemenuLinkActive' : '')} onClick={closeSidemenuClick}><IoMdGlobe /> Countries</NavLink>
                </li> */}
                <li>
                  <NavLink to={`${path}/about`} className={({ isActive }) => (isActive ? 'sidemenuLinkActive' : '')} onClick={closeSidemenuClick}><TbBuilding /> About</NavLink>
                </li>
                <li>
                  <NavLink to={`${path}/service`} className={() => (isServiceJob() ? 'sidemenuLinkActive' : '')} onClick={closeSidemenuClick}><TbHeartHandshake /> Service</NavLink>
                </li>
                <li>
                  <NavLink to={`${path}/blog`} className={() => (isBlogJob() ? 'sidemenuLinkActive' : '')} onClick={closeSidemenuClick}><TbMessage2Search /> Blogs</NavLink>
                </li>
                <li>
                  <NavLink to={`${path}/testimonials`} className={({ isActive }) => (isActive ? 'sidemenuLinkActive' : '')} onClick={closeSidemenuClick}><TbMessage2Heart /> Testimonials</NavLink>
                </li>
                <li>
                  <NavLink to={`${path}/gallery`} className={({ isActive }) => (isActive ? 'sidemenuLinkActive' : '')} onClick={closeSidemenuClick}><BiImages /> Gallery</NavLink>
                </li>
                <li>
                  <NavLink to={`${path}/contact`} className={({ isActive }) => (isActive ? 'sidemenuLinkActive' : '')} onClick={closeSidemenuClick}><BiSupport /> Contact</NavLink>
                </li>
            </ul>
            {/* <ul>
                <li>
                  <NavLink to={`${path}/`} onClick={closeSidemenuClick} className='logOutBtn'><TbLogout /> Logout</NavLink>
                </li>
            </ul> */}
        </div>
    </aside>

    {isLoginPopupVisible && (
      <div className="loginPopup">
          <div className="closeloginPopup" onClick={closeloginPopup}><GrClose /></div>
          <div className="loginPopupHead">
            <h2 className='mainTitle'>Hey, Welcome!</h2>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Fugiat iste aperiam, reprehenderit.</p>
          </div>
          <div className="loginPopupBody">
            <Link to={`${path}/login`} className='loginBtn' onClick={closeloginPopup}>Login <LuChevronRight /></Link>
            <Link to={`${path}/register`} className='registerBtn' onClick={closeloginPopup}>Register <LuChevronRight /></Link>
          </div>
      </div>
    )}

    </>
  );
}

export default Nav;
