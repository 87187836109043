import React from "react";
import { Suspense, lazy } from 'react';
import HomeContact from "../home/homeContact";
import flag1 from '../../assets/images/country/flag1.gif'
import flag2 from '../../assets/images/country/flag2.gif'
import flag3 from '../../assets/images/country/flag3.gif'
import flag4 from '../../assets/images/country/flag4.gif'
import flag5 from '../../assets/images/country/flag5.gif'
import flag6 from '../../assets/images/country/flag6.gif'
import flag7 from '../../assets/images/country/flag7.gif'
import flag8 from '../../assets/images/country/flag8.gif'

const path = "";

const CountriesBox = lazy(() => import("./countriesBox"));


function Countries() {
    
  return (
    <>
        <section id="countriesBanner">
            <div className="container">
                <div className="countriesBannerMain">
                    <h1>Discover Global <br />Opportunities Worldwide</h1>
                </div>
            </div>
        </section>
        <section id="countriesList">
            <div className="container">
                <div className="countriesListMain">
                    <Suspense>
                        <CountriesBox countryLink={`${path}/countryDetails`} countryFlag={flag1} countryTitle="Canada" countryJobs="120" />
                    </Suspense>
                    <Suspense>
                        <CountriesBox countryLink={`${path}/countryDetails`} countryFlag={flag2} countryTitle="USA" countryJobs="85" />
                    </Suspense>
                    <Suspense>
                        <CountriesBox countryLink={`${path}/countryDetails`} countryFlag={flag3} countryTitle="India" countryJobs="202" />
                    </Suspense>
                    <Suspense>
                        <CountriesBox countryLink={`${path}/countryDetails`} countryFlag={flag4} countryTitle="Germany" countryJobs="20" />
                    </Suspense>
                    <Suspense>
                        <CountriesBox countryLink={`${path}/countryDetails`} countryFlag={flag5} countryTitle="Qatar" countryJobs="132" />
                    </Suspense>
                    <Suspense>
                        <CountriesBox countryLink={`${path}/countryDetails`} countryFlag={flag6} countryTitle="Argentina" countryJobs="46" />
                    </Suspense>
                    <Suspense>
                        <CountriesBox countryLink={`${path}/countryDetails`} countryFlag={flag7} countryTitle="Australia" countryJobs="346" />
                    </Suspense>
                    <Suspense>
                        <CountriesBox countryLink={`${path}/countryDetails`} countryFlag={flag8} countryTitle="Brazil" countryJobs="66" />
                    </Suspense>
                    <Suspense>
                        <CountriesBox countryLink={`${path}/countryDetails`} countryFlag={flag1} countryTitle="Canada" countryJobs="120" />
                    </Suspense>
                    <Suspense>
                        <CountriesBox countryLink={`${path}/countryDetails`} countryFlag={flag2} countryTitle="USA" countryJobs="85" />
                    </Suspense>
                    <Suspense>
                        <CountriesBox countryLink={`${path}/countryDetails`} countryFlag={flag3} countryTitle="India" countryJobs="202" />
                    </Suspense>
                    <Suspense>
                        <CountriesBox countryLink={`${path}/countryDetails`} countryFlag={flag4} countryTitle="Germany" countryJobs="20" />
                    </Suspense>
                    <Suspense>
                        <CountriesBox countryLink={`${path}/countryDetails`} countryFlag={flag5} countryTitle="Qatar" countryJobs="132" />
                    </Suspense>
                    <Suspense>
                        <CountriesBox countryLink={`${path}/countryDetails`} countryFlag={flag6} countryTitle="Argentina" countryJobs="46" />
                    </Suspense>
                    <Suspense>
                        <CountriesBox countryLink={`${path}/countryDetails`} countryFlag={flag7} countryTitle="Australia" countryJobs="346" />
                    </Suspense>
                    <Suspense>
                        <CountriesBox countryLink={`${path}/countryDetails`} countryFlag={flag8} countryTitle="Brazil" countryJobs="66" />
                    </Suspense>
                </div>
            </div>
        </section>
        <HomeContact />
    </>
  );
}

export default Countries;
