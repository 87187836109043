import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { lazy } from 'react';
import { GoChevronRight, GoArrowRight, GoArrowLeft } from "react-icons/go";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';  // Axios for API calls
import { baseURL } from '../axiosInterceptor/axiosInterceptorC'; // Adjust if necessary

const HomeServiceBox = lazy(() => import("./homeServiceBox"));
const path = "";

function HomeService() {
    const [services, setServices] = useState([]); // State for storing service data
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state
    const page = 1; // Default page
    const limit = 5; // Limit blogs to 5
    const searchTerm = ''; 
    const settings = {
        dots: false,
        infinite: false,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 870,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 630,
                settings: {
                    slidesToShow: 1.1,
                },
            },
        ],
    };

    // Fetch services data from API
    useEffect(() => {
        const fetchServices = async () => {
            try {
                const response = await axios.get(`${baseURL}/services`,{params: {
                    page,
                    limit,
                    search: searchTerm,
                },}); // API endpoint for services
                setServices(response.data.data); // Assuming the response structure has a `data` field
                setLoading(false);
                console.log(response.data.data)
            } catch (err) {
                console.log(err)
                setError('Failed to fetch services');
                setLoading(false);
            }
        };

        fetchServices();
    }, []);

    return (
        <section id="homeService">
            <div className="container">
                <div className="titleHead">
                    <h2 className="title">What we do</h2>
                </div>
                <div className="homeServiceMain">
                    <div className="homeServiceCol1">
                        <div className="homeServiceCol1Heading">
                            <h3 className="mainTitle">Seamless Job Search and Career Advancement</h3>
                        </div>
                        <div className="homeServiceCol1Details">
                            <p>
                                Discover your next career move with our comprehensive Job Search feature. Seamlessly search, apply, and track opportunities, all within one platform. Empower your professional journey with tailored job recommendations, real-time alerts, and advanced search tools designed to match you with the role of your dreams.
                            </p>
                            <Link to={`${path}/service`}>View More <GoChevronRight /></Link>
                        </div>
                    </div>
                    <div className="homeServiceCol2">
                        {loading ? (
                            <Slider {...settings}>
                            
                                <div className="item" key={1}>
                                    <HomeServiceBox 
                                        homeServiceBoxLink={`${path}/innerService`} 
                                        homeServiceBoxThumbnail={`https://danielsjobfinder.com/demo3/public/hi`|| ''} // Replace with dynamic image URL if available
                                        homeServiceBoxTitle={ "No title available"} 
                                        homeServiceBoxDesc={ "No description available"} 
                                    />
                                </div>
                                <div className="item" key={2}>
                                    <HomeServiceBox 
                                        homeServiceBoxLink={`${path}/innerService`} 
                                        homeServiceBoxThumbnail={`https://danielsjobfinder.com/demo3/public/hi`|| ''} // Replace with dynamic image URL if available
                                        homeServiceBoxTitle={ "No title available"} 
                                        homeServiceBoxDesc={ "No description available"} 
                                    />
                                </div>
                                <div className="item" key={3}>
                                    <HomeServiceBox 
                                        homeServiceBoxLink={`${path}/innerService`} 
                                        homeServiceBoxThumbnail={`https://danielsjobfinder.com/demo3/public/hi`|| ''} // Replace with dynamic image URL if available
                                        homeServiceBoxTitle={ "No title available"} 
                                        homeServiceBoxDesc={ "No description available"} 
                                    />
                                </div>
                           
                        </Slider>
                        ) : error ? (
                            <p>{error}</p>
                        ) : (
                            <Slider {...settings}>
                                {services.map((service, index) => (
                                    <div className="item" key={index}>
                                        <HomeServiceBox 
                                            homeServiceBoxLink={`${path}/innerService/${service.id}`} 
                                            homeServiceBoxThumbnail={`https://danielsjobfinder.com/demo3/public/${service.service_image}`|| ''} // Replace with dynamic image URL if available
                                            homeServiceBoxTitle={service.service_name || "No title available"} 
                                            homeServiceBoxDesc={service.service_description || "No description available"} 
                                        />
                                    </div>
                                ))}
                            </Slider>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <GoArrowRight />
        </button>
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <GoArrowLeft />
        </button>
    );
}

export default HomeService;
