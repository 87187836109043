import React, { useState } from 'react';
import { IoChevronDownOutline } from "react-icons/io5";

import Select from "react-select";

const dateofBirth = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
  { value: "25", label: "25" },
  { value: "26", label: "26" },
  { value: "27", label: "27" },
  { value: "28", label: "28" },
  { value: "29", label: "29" },
  { value: "30", label: "30" },
  { value: "31", label: "31" },
];

const monthofBirth = [
  { value: "January", label: "January" },
  { value: "February", label: "February" },
  { value: "March", label: "March" },
  { value: "April", label: "April" },
  { value: "May", label: "May" },
  { value: "June", label: "June" },
  { value: "July", label: "July" },
  { value: "August", label: "August" },
  { value: "September", label: "September" },
  { value: "October", label: "October" },
  { value: "November", label: "November" },
  { value: "December", label: "December" },
];

const yearofBirth = [
  { value: "2024", label: "2024" },
  { value: "2023", label: "2023" },
  { value: "2022", label: "2022" },
  { value: "2021", label: "2021" },
  { value: "2020", label: "2020" },
  { value: "2019", label: "2019" },
  { value: "2018", label: "2018" },
  { value: "2017", label: "2017" },
  { value: "2016", label: "2016" },
  { value: "2015", label: "2015" },
  { value: "2014", label: "2014" },
  { value: "2013", label: "2013" },
  { value: "2012", label: "2012" },
  { value: "2011", label: "2011" },
  { value: "2010", label: "2010" },
  { value: "2009", label: "2009" },
  { value: "2008", label: "2008" },
  { value: "2007", label: "2007" },
  { value: "2006", label: "2006" },
  { value: "2005", label: "2005" },
  { value: "2004", label: "2004" },
  { value: "2003", label: "2003" },
  { value: "2002", label: "2002" },
  { value: "2001", label: "2001" },
  { value: "2000", label: "2000" },
  { value: "1999", label: "1999" },
  { value: "1998", label: "1998" },
  { value: "1997", label: "1997" },
  { value: "1996", label: "1996" },
  { value: "1995", label: "1995" },
  { value: "1994", label: "1994" },
  { value: "1993", label: "1993" },
  { value: "1992", label: "1992" },
  { value: "1991", label: "1991" },
  { value: "1990", label: "1990" },
  { value: "1989", label: "1989" },
  { value: "1988", label: "1988" },
  { value: "1987", label: "1987" },
  { value: "1986", label: "1986" },
  { value: "1985", label: "1985" },
  { value: "1984", label: "1984" },
  { value: "1983", label: "1983" },
  { value: "1982", label: "1982" },
  { value: "1981", label: "1981" },
  { value: "1980", label: "1980" },
  { value: "1979", label: "1979" },
  { value: "1978", label: "1978" },
  { value: "1977", label: "1977" },
  { value: "1976", label: "1976" },
  { value: "1975", label: "1975" },
  { value: "1974", label: "1974" },
  { value: "1973", label: "1973" },
  { value: "1972", label: "1972" },
  { value: "1971", label: "1971" },
  { value: "1970", label: "1970" },
  { value: "1969", label: "1969" },
  { value: "1968", label: "1968" },
  { value: "1967", label: "1967" },
  { value: "1966", label: "1966" },
  { value: "1965", label: "1965" },
  { value: "1964", label: "1964" },
  { value: "1963", label: "1963" },
  { value: "1962", label: "1962" },
  { value: "1961", label: "1961" },
  { value: "1960", label: "1960" },
  { value: "1959", label: "1959" },
  { value: "1958", label: "1958" },
  { value: "1957", label: "1957" },
  { value: "1956", label: "1956" },
  { value: "1955", label: "1955" },
  { value: "1954", label: "1954" },
  { value: "1953", label: "1953" },
  { value: "1952", label: "1952" },
  { value: "1951", label: "1951" },
  { value: "1950", label: "1950" }
];

const proficiency = [
  { value: "Beginner", label: "Beginner" },
  { value: "Proficient", label: "Proficient" },
  { value: "Expert", label: "Expert" },
];

function PersonalDetailsForm({ formDataperson, setFormDataperson }) {
  const [isClearable] = useState(true);
  const [activeIndex, setActiveIndex] = useState(null);
  const [languages, setLanguages] = useState([]);

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 100,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#d2e2ff' : state.isFocused ? '#eee' : 'white',
      color: state.isSelected ? '#000' : 'inherit',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#000',
    }),
  };

  const toggleSection = (index) => {
    setActiveIndex(prevIndex => (prevIndex === index ? null : index));
  };

  const addLanguage = () => {
    setLanguages([...languages, { id: Date.now(), language: '', proficiency: '', skills: { read: false, write: false, speak: false } }]);
  };

  const deleteLanguage = (id) => {
    setLanguages(languages.filter(language => language.id !== id));
  };

  const handleInputChange = (id, e) => {
    const { name, value } = e.target; // Extract name and value from the input field
    setLanguages((prevLanguages) =>
      prevLanguages.map((language) =>
        language.id === id ? { ...language, [name]: value } : language
      )
    );
    setFormDataperson((prevLanguages) =>
      prevLanguages.map((language) =>
        language.id === id ? { ...language, [name]: value } : language
      )
    );
  };

  const handleSkillChange = (id, skill) => {
    setLanguages(languages.map(language =>
      language.id === id ? { ...language, skills: { ...language.skills, [skill]: !language.skills[skill] } } : language
    ));
  };



  // const handleInputChange = (id, field, value) => {
  //   setFormDataperson((prevLanguages) =>
  //     prevLanguages.map((language) =>
  //       language.id === id ? { ...language, [field]: value } : language
  //     )
  //   );
  // };

  // const handleSkillChange = (id, skill) => {
  //   setFormDataperson((prevLanguages) =>
  //     prevLanguages.map((language) =>
  //       language.id === id
  //         ? {
  //             ...language,
  //             skills: { ...language.skills, [skill]: !language.skills[skill] },
  //           }
  //         : language
  //     )
  //   );
  // };


  const handleGenderChange = (e) => {
    setFormDataperson((prevData) => ({
      ...prevData,
      gender: e.target.value,
    }));
  };

  
  const handlemaritalChange = (e) => {
    setFormDataperson((prevData) => ({
      ...prevData,
      maritalStatus: e.target.value,
    }));
  };
  
  const handleDateOfBirthChange = (e) => {
    setFormDataperson((prevData) => ({
      ...prevData,
      dateOfBirth: e.target.value,
    }));
  };

  const handleMonthOfBirthChange = (e) => {
    setFormDataperson((prevData) => ({
      ...prevData,
      monthOfBirth: e.target.value,
    }));
  };

  const handleYearOfBirthChange = (e) => {
    setFormDataperson((prevData) => ({
      ...prevData,
      yearOfBirth: e.target.value,
    }));
  };

  const handlechangeadress = (e) => {
    setFormDataperson((prevData) => ({
      ...prevData,
      permenentadress: e.target.value,
    }));
  };
  const handlechangehometown = (e) => {
    setFormDataperson((prevData) => ({
      ...prevData,
      hometown: e.target.value,
    }));
  };
  const handlechangepincode = (e) => {
    setFormDataperson((prevData) => ({
      ...prevData,
      pincode: e.target.value,
    }));
  };
  return (
    <>
      <div className="personalDetailsForm">
        <div className="formGroup col-1">
          <label htmlFor="">Gender</label>
          <div className="formRadioGroupButton">
            <div className="formRadioGroupBoxButton">
              <input type="radio" id='male' name="gender" value="male" onChange={handleGenderChange}/>
              <label htmlFor="male">Male</label>
            </div>
            <div className="formRadioGroupBoxButton">
              <input type="radio" id='female' name="gender" value="female" onChange={handleGenderChange}/>
              <label htmlFor="female">Female</label>
            </div>
            <div className="formRadioGroupBoxButton">
              <input type="radio" id='other' name="gender" value="other" onChange={handleGenderChange}/>
              <label htmlFor="other">Other</label>
            </div>
          </div>
        </div>
        <div className="formGroup col-1">
          <label htmlFor="">Marital status</label>
          <div className="formRadioGroupButton">
            <div className="formRadioGroupBoxButton">
              <input type="radio" id='single' name="maritalStatus"  value="single/unmarried" onChange={handlemaritalChange}/>
              <label htmlFor="single">Single / Unmarried</label>
            </div>
            <div className="formRadioGroupBoxButton">
              <input type="radio" id='married' name="maritalStatus" value="married" onChange={handlemaritalChange}/>
              <label htmlFor="married">Married</label>
            </div>
            <div className="formRadioGroupBoxButton">
              <input type="radio" id='widowed' name="maritalStatus" value="widowed" onChange={handlemaritalChange}/>
              <label htmlFor="widowed">Widowed</label>
            </div>
            <div className="formRadioGroupBoxButton">
              <input type="radio" id='divorced' name="maritalStatus" value="divorced" onChange={handlemaritalChange}/>
              <label htmlFor="divorced">Divorced</label>
            </div>
            <div className="formRadioGroupBoxButton">
              <input type="radio" id='separated' name="maritalStatus" value="seperated" onChange={handlemaritalChange}/>
              <label htmlFor="separated">Separated</label>
            </div>
            <div className="formRadioGroupBoxButton">
              <input type="radio" id='other2' name="maritalStatus" value="other" onChange={handlemaritalChange}/>
              <label htmlFor="other2">Other</label>
            </div>
          </div>
        </div>
        <div className="formGroup col-1">
          <label htmlFor="">Date of birth</label>
          <div className="formGroupDorpdownList">
            <div className="formGroupDorpdownListBox dorpdownList-Col-3">
              <Select
                options={dateofBirth}
                placeholder="Select Date"
                isSearchable={true}
                className="customSelect"
                isClearable={isClearable}
                styles={customStyles}
                onChange={handleDateOfBirthChange}

              />
            </div>
            <div className="formGroupDorpdownListBox dorpdownList-Col-3">
              <Select
                options={monthofBirth}
                placeholder="Select Month"
                isSearchable={true}
                className="customSelect"
                isClearable={isClearable}
                styles={customStyles}
                onChange={handleMonthOfBirthChange}
              />
            </div>
            <div className="formGroupDorpdownListBox dorpdownList-Col-3">
              <Select
                options={yearofBirth}
                placeholder="Select Year"
                isSearchable={true}
                className="customSelect"
                isClearable={isClearable}
                styles={customStyles}
                onChange={handleYearOfBirthChange}
              />
            </div>
          </div>
        </div>
        <div className="formGroup col-1">
          <label htmlFor="">Permanent address</label>
          <textarea name="permenentadress" onChange={handlechangeadress}></textarea>
        </div>
        <div className="formGroup">
          <label htmlFor="">Home Town</label>
          <input type="text" name="hometown" onChange={handlechangehometown}/>
        </div>
        <div className="formGroup">
          <label htmlFor="">Pincode</label>
          <input type="text" name="pincode" onChange={handlechangepincode}/>
        </div>
      </div>

      <div className="eductionForm">
        <div className="eductionFormBox">
          <div className="eductionFormBoxHead" onClick={() => toggleSection(0)}>
            <h3>Language proficiency</h3>
            <IoChevronDownOutline />
          </div>
          <div className={`eductionFormBoxBody ${activeIndex === 0 ? 'eductionFormBoxBodyActive' : ''}`}>
            <div className="languageDetailsForm">
            <div className="languageDetailsFormBox">
                <div className="formGroup">
                  <label htmlFor="">Language</label>
                  <input type="text" name="language"/>
                </div>
                <div className="formGroup">
                  <label htmlFor="">Proficiency</label>
                  <div className="formGroupDorpdownList">
                    <div className="formGroupDorpdownListBox">
                      <Select
                        options={proficiency}
                        placeholder="Proficiency"
                        isSearchable={true}
                        className="customSelect"
                        isClearable={isClearable}
                        styles={customStyles}
                        //onChange={selectproficiancy}
                      />
                    </div>
                  </div>
                </div>
                <div className="languageDetailsFormBoxFooter">
                  <div className="languageDetailsFormBoxFooterCol1">
                      <div className="formGroupCheckboxArea">
                          <ul>
                              <li>
                                  <input type="checkbox" id='Read' value="Read"/>
                                  <label htmlFor="Read">
                                      <div className="checkboxIcon"></div>
                                      <div className="checkboxText">Read</div>
                                  </label>
                              </li>
                              <li>
                                  <input type="checkbox" id='Write' value="Write"/>
                                  <label htmlFor="Write">
                                      <div className="checkboxIcon"></div>
                                      <div className="checkboxText">Write</div>
                                  </label>
                              </li>
                              <li>
                                  <input type="checkbox" id='Speak' value="Speak"/>
                                  <label htmlFor="Speak">
                                      <div className="checkboxIcon"></div>
                                      <div className="checkboxText">Speak</div>
                                  </label>
                              </li>
                          </ul>
                      </div>
                  </div>
                </div>
              </div>
              
              {languages.map((language, index) => (
        <div className="languageDetailsFormBox" key={language.id}>
          <div className="formGroup">
            <label htmlFor="">Language</label>
            <input
              type="text"
              value={language.language}
              onChange={(e) => handleInputChange(language.id, 'language', e.target.value)}
            />
          </div>

          <div className="formGroup">
            <label htmlFor="">Proficiency</label>
            <div className="formGroupDorpdownList">
              <div className="formGroupDorpdownListBox">
                <Select
                  options={proficiency}
                  placeholder="Proficiency"
                  isSearchable={true}
                  className="customSelect"
                  isClearable={isClearable}
                  styles={customStyles}
                  value={proficiency.find((option) => option.value === language.proficiency)}
                  onChange={(selectedOption) => handleInputChange(language.id, 'proficiency', selectedOption?.value)}
                />
              </div>
            </div>
          </div>

          <div className="languageDetailsFormBoxFooter">
            <div className="languageDetailsFormBoxFooterCol1">
              <div className="formGroupCheckboxArea">
                <ul>
                  <li>
                    <input
                      type="checkbox"
                      id={`read-${language.id}`}
                      checked={language.skills.read}
                      onChange={() => handleSkillChange(language.id, 'read')}
                    />
                    <label htmlFor={`read-${language.id}`}>
                      <div className="checkboxIcon"></div>
                      <div className="checkboxText">Read</div>
                    </label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id={`write-${language.id}`}
                      checked={language.skills.write}
                      onChange={() => handleSkillChange(language.id, 'write')}
                    />
                    <label htmlFor={`write-${language.id}`}>
                      <div className="checkboxIcon"></div>
                      <div className="checkboxText">Write</div>
                    </label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id={`speak-${language.id}`}
                      checked={language.skills.speak}
                      onChange={() => handleSkillChange(language.id, 'speak')}
                    />
                    <label htmlFor={`speak-${language.id}`}>
                      <div className="checkboxIcon"></div>
                      <div className="checkboxText">Speak</div>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div className="languageDetailsFormBoxFooterCol2">
              <div className="deleteLanguageBtn" onClick={() => deleteLanguage(language.id)}>
                Delete
              </div>
            </div>
          </div>
        </div>
      ))}
              <div className="addLanguageDetailsFormBoxArea">
                <div className="addLanguageBtn" onClick={addLanguage}>Add another language</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PersonalDetailsForm;
