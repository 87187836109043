import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";
import Select from "react-select";

    const designations = [
        { value: "developer", label: "Developer" },
        { value: "designer", label: "Designer" },
        { value: "manager", label: "Manager" },
        // Add more options here
    ];

    const locations = [
        { value: "new-york", label: "New York" },
        { value: "san-francisco", label: "San Francisco" },
        { value: "los-angeles", label: "Los Angeles" },
        // Add more options here
    ];

    const experiences = [
        { value: "0-1", label: "0-1 Yrs" },
        { value: "2-5", label: "2-5 Yrs" },
        { value: "6-10", label: "6-10 Yrs" },
        // Add more options here
    ];

function HomeBanner() {
    const [isClearable] = useState(true);

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 100,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#d2e2ff' : state.isFocused ? '#eee' : 'white',
            color: state.isSelected ? '#000' : 'inherit',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#000',
        }),
    };

    return (
        <section id="homeBanner">
            <div className="container">
                <div className="homeBannerMain">
                    <h1>Your Gateway to New Opportunities</h1>
                    <p>It’s time to accelerate your search. Find and secure the role of your dreams without leaving the platform thanks to our all-encompassing Job Search feature.</p>
                    <div className="bannerForm">
                        <form action="">
                            <div className="formGroupBox">
                                <div className="formGroup">
                                    <Select
                                        options={designations}
                                        placeholder="Designation"
                                        className="customSelect"
                                        isClearable={isClearable}
                                        styles={customStyles}
                                    />
                                </div>
                                <div className="formGroup">
                                    <Select
                                        options={locations}
                                        placeholder="Location"
                                        isSearchable={true}
                                        className="customSelect"
                                        isClearable={isClearable}
                                        styles={customStyles}
                                    />
                                </div>
                                <div className="formGroup">
                                    <Select
                                        options={experiences}
                                        placeholder="Experience"
                                        className="customSelect"
                                        isClearable={isClearable}
                                        styles={customStyles}
                                    />
                                </div>
                            </div>
                            <div className="formBtnArea">
                                <button className="formSbtBtn">
                                    <FiSearch /> Search
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HomeBanner;
