import React from 'react';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import { RiDeleteBinLine, RiDownloadLine } from "react-icons/ri";

function UploadResume({ onHandleImageUpload }) {

    const [resume, setResume] = React.useState(null);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [isPopupVisible, setIsPopupVisible] = React.useState(false);

    const shimmer = document.querySelector('.shimmer');
    const body = document.querySelector('body');
    const applyJobFormList = document.querySelector('.applyJobFormList');

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        const isValidFileType = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(file.type);
        const isValidFileSize = file.size <= 2 * 1024 * 1024; // 2MB
    
        if (isValidFileType && isValidFileSize) {
            setResume(file);
            onHandleImageUpload(file); // Call the function passed as a prop
            setAlertMessage(''); // Clear alert message on valid upload
        } else {
            setAlertMessage('Please upload a valid file type (doc, docx, pdf) with size less than 2MB.');
        }
    };

    const handleRemoveResume = () => {
        setResume(null);
        setAlertMessage(''); // Clear alert message when resume is removed
    };

    const handleDeleteResumeClick = () => {
        setIsPopupVisible(true);
        shimmer.style.display = 'block';
        body.style.overflow = 'hidden';
        applyJobFormList.style.position = 'inherit';
    };

    const handleCancelClick = () => {
        setIsPopupVisible(false);
        shimmer.style.display = 'none';
        body.style.overflow = 'inherit';
        applyJobFormList.style.position = 'sticky';
    };

    const handleConfirmDeleteClick = () => {
        handleRemoveResume();
        setIsPopupVisible(false);
        shimmer.style.display = 'none';
        body.style.overflow = 'inherit';
        applyJobFormList.style.position = 'sticky';
        toast.success('Resume deleted successfully!', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'light',
        });
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: '.doc,.docx,.pdf',
        maxSize: 2 * 1024 * 1024,
    });
    
    return (
        <>
            {isPopupVisible && (
                <div className="deleteConfirmPopup">
                    <div className="deleteConfirmPopupMain">
                        <h2>Are you sure you want to delete the resume?</h2>
                        <div className="deleteConfirmPopupFooter">
                            <div className="closeDeleteConfirmPopup" onClick={handleCancelClick}>Cancel</div>
                            <div className="confirmDeleteConfirmPopup" onClick={handleConfirmDeleteClick}>Delete</div>
                        </div>
                    </div>
                </div>
            )}

            <div {...getRootProps({ className: 'uploadResumeBox' })}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                    <p>Drop the files here ...</p> :
                    <p>Drag & drop a resume here, or click to select one</p>
                }
            </div>
            {alertMessage && (
                <div className="uploadResumeAlert">{alertMessage}</div>
            )}
            {resume && (
                <div className="resumeList">
                    <div className="resumeListBox">
                        <div className="resumeListBoxCol1">
                            <p>{resume.name}</p>
                        </div>
                        <div className="resumeListBoxCol2">
                            <a href={URL.createObjectURL(resume)} download className="resumeListbtn downloadResume">
                                <RiDownloadLine />
                            </a>
                            <button type="button" className='resumeListbtn deleteResume' onClick={handleDeleteResumeClick}>
                                <RiDeleteBinLine />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default UploadResume;
