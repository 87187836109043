import React, { useState } from 'react';
import { MdOutlineModeEdit, MdOutlineCheckCircle } from "react-icons/md";
import { RiDeleteBinLine, RiDownloadLine } from "react-icons/ri";
import { BiCloudUpload } from "react-icons/bi";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

function ReviewForm({ setValue }) {

    const [resumeList, setResumeList] = useState([
        'Proposal for Zinnia Naturals.docx (1)_removed.pdf',
        'myresumeNew.pdf'
    ]);
    const [educationList, setEducationList] = useState([
        {
            id: 1,
            degree: 'Class 10th',
            board: 'CBSE',
            passingYear: '2024'
        },
        {
            id: 2,
            degree: 'Class 12th',
            board: 'CBSE',
            passingYear: '2024'
        },
        {
            id: 3,
            degree: 'Masters / Post-Graduation',
            university: 'Harvard University',
            course: 'Computer Science',
            specialization: 'Machine Learning',
            courseType: 'Full Time',
            duration: '2020 - 2024'
        },
        {
            id: 4,
            degree: 'Doctorate / PhD',
            university: 'Harvard University',
            course: 'Computer Science',
            specialization: 'Machine Learning',
            courseType: 'Full Time',
            duration: '2020 - 2024'
        },
    ]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [itemType, setItemType] = useState('');
    // const [selectedResume, setSelectedResume] = React.useState(null);
    const [isPopupVisible, setIsPopupVisible] = React.useState(false);
    const shimmer = document.querySelector('.shimmer');
    const body = document.querySelector('body');
    const applyJobFormList = document.querySelector('.applyJobFormList');

    const handleDeleteClick = (item, type) => {
        setSelectedItem(item);
        setItemType(type);
        setIsPopupVisible(true);
        document.querySelector('.shimmer').style.display = 'block';
        document.querySelector('body').style.overflow = 'hidden';
        document.querySelector('.applyJobFormList').style.position = 'inherit';
    };

    // const handleDeleteResumeClick = (resume) => {
    //     setSelectedResume(resume);
    //     setIsPopupVisible(true);
    //     shimmer.style.display = 'block';
    //     body.style.overflow = 'hidden';
    //     applyJobFormList.style.position = 'inherit';
    // };

    const handleCancelClick = () => {
        setIsPopupVisible(false);
        shimmer.style.display = 'none';
        body.style.overflow = 'inherit';
        applyJobFormList.style.position = 'sticky';
    };

    const handleConfirmDeleteClick = () => {
        if (itemType === 'resume') {
            setResumeList(prevList => prevList.filter(resume => resume !== selectedItem));
            toast.success('Resume deleted successfully!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: 'light',
            });
        } else if (itemType === 'education') {
            setEducationList(prevList => prevList.filter(education => education.id !== selectedItem.id));
            toast.success('Education detail deleted successfully!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: 'light',
            });
        }
        setSelectedItem(null);
        setIsPopupVisible(false);
        document.querySelector('.shimmer').style.display = 'none';
        document.querySelector('body').style.overflow = 'inherit';
        document.querySelector('.applyJobFormList').style.position = 'sticky';
    };

    const handleEditClick = (tabIndex) => {
        setValue(tabIndex);
    };

  return (
    <>
    {isPopupVisible && (
        <div className="deleteConfirmPopup">
            <div className="deleteConfirmPopupMain">
                <h2>
                    {itemType === 'resume' 
                        ? 'Are you sure you want to delete this resume?' 
                        : 'Are you sure you want to delete this education detail?'}
                </h2>
                <div className="deleteConfirmPopupFooter">
                    <div className="closeDeleteConfirmPopup" onClick={handleCancelClick}>Cancel</div>
                    <div className="confirmDeleteConfirmPopup" onClick={handleConfirmDeleteClick}>Delete</div>
                </div>
            </div>
        </div>
    )}
        <div className="reviewBoxList">
            <div className="reviewBox">
                <div className="reviewBoxHead">
                    <h2 className="subTitle">Profile</h2>
                    <div className="reviewBoxHeadEditBtn">
                        <MdOutlineModeEdit />
                        <div className="reviewBoxHeadEditBtnText" onClick={() => handleEditClick(0)}>Edit</div>
                    </div>
                </div>
                <div className="reviewBoxBody">
                    <div className="formGroup">
                        <label htmlFor="">Name</label>
                        <h4>Afsal Nazeer</h4>
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">Phone Number</label>
                        <h4>7356339750</h4>
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">Email</label>
                        <h4>afsal@esightsolutions.com</h4>
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">Work Status</label>
                        <h4>Fresher</h4>
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">Current location</label>
                        <h4>Kerala, India</h4>
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">Profile Summary</label>
                        <h4>My Name is AFSAL K T . I am a Web designer and Web developer , and English , Arabic typer . I am looking for this type of job.</h4>
                    </div>
                </div>
            </div>
            <div className="reviewBox">
                <div className="reviewBoxHead">
                    <h2 className="subTitle">Resume</h2>
                    <div className="reviewBoxHeadEditBtn">
                        <BiCloudUpload />
                        <div className="reviewBoxHeadEditBtnText" onClick={() => handleEditClick(1)}>Upload Resume</div>
                    </div>
                </div>
                <div className="reviewBoxBody">
                    <div className="resumeList">
                        {resumeList.map((resume, index) => (
                            <div className="resumeListBox" key={index}>
                                <div className="resumeListBoxCol1">
                                    <p>{resume}</p>
                                </div>
                                <div className="resumeListBoxCol2">
                                    <Link to="" className="resumeListbtn downloadResume" download>
                                        <RiDownloadLine />
                                    </Link>
                                    <button 
                                        type="button" 
                                        className='resumeListbtn deleteResume' 
                                        onClick={() => handleDeleteClick(resume, 'resume')}>
                                        <RiDeleteBinLine />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="reviewBox">
                <div className="reviewBoxHead">
                    <h2 className="subTitle">Education Details</h2>
                    <div className="reviewBoxHeadEditBtn">
                        <MdOutlineModeEdit />
                        <div className="reviewBoxHeadEditBtnText" onClick={() => handleEditClick(2)}>Edit</div>
                    </div>
                </div>
                <div className="reviewBoxBody">
                    <div className="educationList">
                        {educationList.map((education) => (
                            <div className="educationListBox" key={education.id}>
                                <div className="educationListBoxHead">
                                    <div className="educationListBoxHeadCol1">
                                        <div className="educationTitle">{education.degree}</div>
                                    </div>
                                    <div className="educationListBoxHeadCol2">
                                        <button 
                                            type="button" 
                                            className='resumeListbtn educationDeleteBtn' 
                                            onClick={() => handleDeleteClick(education, 'education')}>
                                            <RiDeleteBinLine />
                                        </button>
                                    </div>
                                </div>
                                <div className="educationListBoxBody">
                                    <div className="formGroup">
                                        <label htmlFor="">University/Institute</label>
                                        <h4>{education.university}</h4>
                                    </div>
                                    <div className="formGroup">
                                        <label htmlFor="">Course</label>
                                        <h4>{education.course}</h4>
                                    </div>
                                    <div className="formGroup">
                                        <label htmlFor="">Specialization</label>
                                        <h4>{education.specialization}</h4>
                                    </div>
                                    <div className="formGroup">
                                        <label htmlFor="">Course type</label>
                                        <h4>{education.courseType}</h4>
                                    </div>
                                    <div className="formGroup">
                                        <label htmlFor="">Course duration</label>
                                        <h4>{education.duration}</h4>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="reviewBox">
                <div className="reviewBoxHead">
                    <h2 className="subTitle">Personal Details</h2>
                    <div className="reviewBoxHeadEditBtn">
                        <MdOutlineModeEdit />
                        <div className="reviewBoxHeadEditBtnText" onClick={() => handleEditClick(3)}>Edit</div>
                    </div>
                </div>
                <div className="reviewBoxBody">
                    <div className="formGroup">
                        <label htmlFor="">Gender</label>
                        <h4>Male</h4>
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">Marital status</label>
                        <h4>Single / Unmarried</h4>
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">Date of birth</label>
                        <h4>06 July 1998</h4>
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">Permanent address</label>
                        <h4>Kottiyottuthodi (H), Marayamangalam (PO), Nellaya</h4>
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">Home Town</label>
                        <h4>Cherpulasseri</h4>
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">Pincode</label>
                        <h4>679335</h4>
                    </div>
                    <div className="formGroup col-1">
                        <label htmlFor="">Language Proficiency</label>
                        <div className="languageTable">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Languages</th>
                                        <th>Proficiency</th>
                                        <th>Read</th>
                                        <th>Write</th>
                                        <th>Speak</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Malayalam</td>
                                        <td>Expert</td>
                                        <td><MdOutlineCheckCircle /></td>
                                        <td><MdOutlineCheckCircle /></td>
                                        <td><MdOutlineCheckCircle /></td>
                                    </tr>
                                    <tr>
                                        <td>Tamil</td>
                                        <td>Proficiency</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td><MdOutlineCheckCircle /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

ReviewForm.propTypes = {
    setValue: PropTypes.func.isRequired,
};

export default ReviewForm;
