import React, { useState } from "react";
import { Link,useNavigate } from 'react-router-dom';
import BackBtn from "../settings/backBtn";
import HomeContact from "../home/homeContact";
import UploadResume from './uploadResume';
import EducationForm from "./educationForm";
import PersonalDetailsForm from "./personalDetailsForm";
import ReviewForm from "./reviewForm";
import successImg from '../../assets/images/success.png'
import ProfileImage from "../profile/profileImage";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
//import Loader from '../loader/buttonLoader';
import Select from "react-select";
import axios from 'axios';
import api, { baseURL } from '../axiosInterceptor/axiosInterceptorC';
import { PiCaretRightBold, PiCaretLeftBold } from "react-icons/pi";


const path = "";

//const [errors, setErrors] = useState({});



function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const locations = [
    { value: "new-york", label: "New York" },
    { value: "san-francisco", label: "San Francisco" },
    { value: "los-angeles", label: "Los Angeles" },
    // Add more options here
];

const place = [
    { value: "new-york", label: "New York" },
    { value: "san-francisco", label: "San Francisco" },
    { value: "los-angeles", label: "Los Angeles" },
    // Add more options here
];

const year = [
    { value: "1year", label: "1 Year" },
    { value: "2years", label: "2 Years" },
    { value: "3years", label: "3 Years" },
    { value: "4years", label: "4 Years" },
    { value: "5years", label: "5 Years" },
    { value: "6years", label: "6 Years" },
    { value: "7years", label: "7 Years" },
    { value: "8years", label: "8 Years" },
    { value: "9years", label: "9 Years" },
    { value: "10years", label: "10 Years" },
    { value: "10Above", label: "10+ Years" },
    // Add more options here
];

const month = [
    { value: "1month", label: "1 Month" },
    { value: "2month", label: "2 Months" },
    { value: "3month", label: "3 Months" },
    { value: "4month", label: "4 Months" },
    { value: "5month", label: "5 Months" },
    { value: "6month", label: "6 Months" },
    { value: "7month", label: "7 Months" },
    { value: "8month", label: "8 Months" },
    { value: "9month", label: "9 Months" },
    { value: "10month", label: "10 Months" },
    { value: "11month", label: "11 Months" },
    // Add more options here
];

function ApplyJobForm() {
    const [isClearable] = useState(true);
    const [value, setValue] = React.useState(0);
    const [workStatus, setWorkStatus] = useState('fresher');
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const shimmer = document.querySelector('.shimmer');
    const body = document.querySelector('body');
    const [isLoading, setIsLoading] = useState(false);
    const applyJobFormList = document.querySelector('.applyJobFormList');
    const [inputs, setInputs] = useState({});
   const[croppedimages,setCroppedimage]=useState({});
   
    const [message, setMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showFiledAlert, setShowFiledAlert] = useState(false);

    const [serviceImage, setServiceImage] = useState(null);
    const [isImageUploaded, setIsImageUploaded] = useState(false);
    const [pdfFile,setPdfFile] = useState(null);
    const [pdfError,setPdfError] = useState(null);
   // const [errors, setErrors] = useState({});  
    const navigate = useNavigate();

    const handleImageUpload = (file) => {
        setPdfFile(file);
        console.log(file)
        if (file) {
            setIsImageUploaded(true);
            setErrors(prev => ({ ...prev, serviceImage: '' }));
        } else {
            setIsImageUploaded(false);
       }
   };

    console.log(workStatus)
    const handleChange = (event, newValue) => {
        setValue(newValue);
        window.scrollTo(0, 0); // Scrolls to the top of the page
    };

    const handleNextClick = () => {
        if (value < 4) {  // Assuming there are 5 tabs (0 to 4)
            setValue((prevValue) => prevValue + 1);
        } else {
            setShowSuccessPopup(true);
            shimmer.style.display = 'block';
            body.style.overflow = 'hidden';
            applyJobFormList.style.position = 'inherit'; // Show success popup on last tab
        }
        window.scrollTo(0, 0);
    };

    const closeSuccessPopup = () => {
        shimmer.style.display = 'none';
        body.style.overflow = 'inherit';
        applyJobFormList.style.position = 'sticky';
    };

    const handlePrevClick = () => {
        if (value > 0) {  // Ensures that the tab index doesn't go below 0
            setValue((prevValue) => prevValue - 1);
        }
        window.scrollTo(0, 0);
    };

    const handleWorkStatusChange = (event) => {
        setWorkStatus(event.target.id); // 'fresher' or 'experience'
    };

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 100,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#d2e2ff' : state.isFocused ? '#eee' : 'white',
            color: state.isSelected ? '#000' : 'inherit',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#000',
        }),
    };


    const [formData, setFormData] = useState({
        firstname:'',
        lastname:'',
        phonenumber:'',
        emailid:'',
        totalexperince:'',
        numbermonth:'',
        counrtryselect:'',
        placeselect:'',
        profilesummary:'',
      });

      const [errors, setErrors] = useState({
        firstname:'',
        lastname:'',
        phonenumber:'',
        emailid:'',
        totalexperince:'',
        numbermonth:'',
        counrtryselect:'',
        placeselect:'',
        profilesummary:'',
    });

    const handleChangev1 = (e) => {
        const name = e.target.name;
        const value = e.target.value.trim();
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
        const error = validate(name, value);
       setErrors((errors) => ({ ...errors, [name]: error[name] }));
        };

        const validate = (name, value) => {
            let error = {};
            if (name === 'job_title' && !value) {
                error.job_title = "Job title is required";
            } 
            return error;
        };

        const validateAllFields = (fields) => {
            let errors = {};
            for (let key in fields) {
                const error = validate(key, fields[key]);
                if (error[key]) {
                    errors[key] = error[key];
                }
            }
            return errors;
        };

        const handleCroppedImage = (blob) => {
            console.log("Cropped Image Blob:", blob);
            setCroppedimage(blob);
            // Handle the blob value here, e.g., set it in state or send it to the backend
          };


          const handletotalexperiance = (selectedOption) => {
            setFormData({
              ...formData,
              totalexperince: selectedOption ? selectedOption.value : '' ,
            });
          };

          const handlenumbermonth = (selectedOption) => {
            setFormData({
              ...formData,
              numbermonth: selectedOption ? selectedOption.value : '' ,
            });
          };

          const handlecounrtyselect = (selectedOption) => {
            
            setFormData({
              ...formData,
              counrtryselect: selectedOption ? selectedOption.value : '' ,
            });
            //console.log(counrtryselect)
          };

          const handleplaceselect = (selectedOption) => {
            setFormData({
              ...formData,
              placeselect: selectedOption ? selectedOption.value : '' ,
            });
          };

          const handleSubmit = async (e) => {
            e.preventDefault();
            if (!validateForm()) {
                return; // Stop submission if validation fails
            }
            setIsLoading(true)
            let year_experince_vals='';
            let month_experince_vals='';
            if(workStatus=='experience'){
                year_experince_vals=formData.totalexperince
                month_experince_vals=formData.numbermonth
            }
            else{
                year_experince_vals=0;
                month_experince_vals=0;
            }

            const data = new FormData(); 
            data.append('first_name', formData.firstname);
            data.append('last_name', formData.lastname);
            data.append('phone_number', formData.phonenumber);
            data.append('email_id', formData.emailid);
            data.append('profile_summary', formData.profilesummary);
            data.append('country_val', formData.counrtryselect);
            data.append('place_val', formData.placeselect);
            data.append('year_experience', year_experince_vals);
            data.append('month_experince',month_experince_vals);
            data.append('work_status',workStatus)
        
            const response = await fetch(croppedimages);
            const blob = await response.blob();
            const uniqueFileName = `croppedImage_image.jpeg`;
            // Convert blob to File
            const file_name = new File([blob], uniqueFileName, { type: blob.type });
            console.log(file_name)
            data.append('filename', file_name);

            const validationErrors = validateAllFields(inputs);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            setMessage('Please correct the errors and try again.');
            setIsSuccess(false);
            setShowFiledAlert(true);
            setShowSuccessAlert(false);
            setIsLoading(false);
        } else{
        
        axios.post(`${baseURL}/addbasicfromdetails`, data).then(response =>{
            console.log(response.data)
            if(response.data.status == 1){
                setMessage(response.data.message);
                setIsSuccess(true);
                setShowSuccessAlert(true);
                setShowFiledAlert(false);
                setTimeout(() => {
                    a11yProps(1)
                },2000)
            }else{
                setMessage(response.data.message);
                setIsSuccess(false);
                setShowFiledAlert(true);
                 setShowSuccessAlert(false);
            }
            setTimeout(() => {
                setIsLoading(false);
            },1500)
        })
        .catch(error => {
            // handle error (e.g., show error message)
            console.log(error)
            setMessage('Failed to add client. Please try again.');
            setIsSuccess(false);
            setShowFiledAlert(true);
             setShowSuccessAlert(false);
             hideAlertAfterDelay();
             setIsLoading(false);
        })
        }
          };
       //upload resume 
       const handleResume = async () => {
            if(!pdfFile){
                setPdfError('*choose resume')
                return false;
            }else{
                const formData = new FormData();
                formData.append('resume', pdfFile);
                
                
                try {
                    const response = await axios.post(`${baseURL}/uploadResume`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    console.log(response.data.status)
                    if (response.data.status) {
                        setValue((prevValue) => prevValue + 1);
                        
                    } else {
                    
                    }
                } catch (error) {
                console.log(error)
                }
            }
       }

console.log(croppedimages)
             //validation
    const validateForm = () => {
        const newErrors = {};
        //Validate Job Title
        if(croppedimages==''){
            newErrors.cropedimagv = 'Please upload image'; 
        }

        if (!formData.firstname.trim()) {
            newErrors.firstname = 'First Name is required';
        }

        if (!formData.lastname.trim()) {
            newErrors.lastname = 'Last Name is required';
        }

        if (!formData.phonenumber.trim()) {
            newErrors.phonenumber = 'Phone number is required';
        }
        if (!formData.emailid.trim()) {
            newErrors.emailid = 'Email id is required';
        }
        if (!formData.profilesummary.trim()) {
            newErrors.profilesummary = 'Profile summary is required';
        }
        if (!formData.counrtryselect.trim()) {
            newErrors.counrtryselect = 'Country is required';
        }
        if (!formData.placeselect.trim()) {
            newErrors.placeselect = 'Place is required';
        }
        if(workStatus=='experience'){
            if (!formData.totalexperince.trim()) {
                newErrors.totalexperince = 'Year Experience is required';
            }
            if (!formData.numbermonth.trim()) {
                newErrors.numbermonth = 'Month Experience is required';
            }

        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const hideAlertAfterDelay = () => {
        setTimeout(() => {
            setShowSuccessAlert(false);
            setShowFiledAlert(false);
            setMessage('');
        }, 10000); // 10000 milliseconds = 10 seconds
    };


    //person details

    const [formDataperson, setFormDataperson] = useState({
        gender: '',
        maritalStatus: '',
        dateOfBirth: '',
        monthOfBirth: '',
        yearOfBirth: '',
        language: [],
      });

    const handlepersondetails = async (e) => {
        e.preventDefault();
        console.log(formDataperson.language)
    }

    return (
        <>
        {showSuccessPopup && (
            <div className="jobAppliedSuccessPopup">
                <div className="jobAppliedSuccessPopupMain">
                    <div className="jobAppliedSuccessPopupImg">
                        <img src={successImg} alt="" />
                    </div>
                    <div className="jobAppliedSuccessPopupDetails">
                        <h2>Job applied successfully</h2>
                        <p>Your application was submitted successfully. We’ll review it and contact you if you're selected.</p>
                        <Link to={`${path}/jobs`} className="okBtn" onClick={closeSuccessPopup}>Okay</Link>
                    </div>
                </div>
            </div>
            )}
            <section id="applyJobForm">
                <div className="container">
                    <BackBtn />
                    <div className="applyJobFormMain">
                        <div className="applyJobFormMenu">
                            <div className="applyJobFormMenuHead">
                                <h2 className="subTitle">Quick links</h2>
                            </div>
                            <div className="applyJobFormMenuBody">
                                <Tabs
                                    orientation="vertical"
                                    variant="scrollable"
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="Vertical tabs example"
                                >
                                    <Tab label="Profile" {...a11yProps(0)} />
                                    <Tab label="Resume" {...a11yProps(1)} />
                                    <Tab label="Education" {...a11yProps(2)} />
                                    <Tab label="Personal Details" {...a11yProps(3)} />
                                    <Tab label="Review" {...a11yProps(4)} />
                                </Tabs>
                            </div>
                        </div>
                        <div className="applyJobFormList">
                            <TabPanel value={value} index={0}>
                                <div className="applyJobFormListBox">
                                    <div className="applyJobFormListBoxHead">
                                        <h2 className="subTitle">Profile Details</h2>
                                    </div>
                                    <div className="applyJobFormListBoxHeadBody">
                                        <form action="" onSubmit={handleSubmit}>
                                            <div className="formGroup col-1">
                                                <label htmlFor="">Profile Image</label>
                                                <ProfileImage onCropCompleteLast={handleCroppedImage}/>
                                                {errors.cropedimagv && <p className="error">{errors.cropedimagv}</p>}
                                            </div>
                                            <div className="formGroup">
                                                <label htmlFor="">First Name</label>
                                                <input type="text" name="firstname" onKeyUp={handleChangev1} onChange={handleChangev1}/>
                                                {errors.firstname && <p className="error">{errors.firstname}</p>}
                                            </div>
                                            <div className="formGroup">
                                                <label htmlFor="">Last Name</label>
                                                <input type="text" name="lastname" onKeyUp={handleChangev1} onChange={handleChangev1}/>
                                                {errors.lastname && <p className="error">{errors.lastname}</p>}
                                            </div>
                                            <div className="formGroup">
                                                <label htmlFor="">Phone Number</label>
                                                <input type="number" name="phonenumber" onKeyUp={handleChangev1} onChange={handleChangev1}/>
                                                {errors.phonenumber && <p className="error">{errors.phonenumber}</p>}
                                            </div>
                                            <div className="formGroup">
                                                <label htmlFor="">Email</label>
                                                <input type="email" name="emailid" onKeyUp={handleChangev1} onChange={handleChangev1}/>
                                                {errors.emailid && <p className="error">{errors.emailid}</p>}
                                            </div>
                                            <div className="formGroup">
                                                <label htmlFor="">Work Status</label>
                                                <div className="formRadioGroup">
                                                    <div className="formRadioGroupBox">
                                                        <input type="radio" id='fresher' name="workStatus" checked={workStatus === 'fresher'} 
                                                            onChange={handleWorkStatusChange} />
                                                        <label htmlFor="fresher">
                                                            <div className="radioIcon"></div>
                                                            <div className="radioText">Fresher</div>
                                                        </label>
                                                    </div>
                                                    <div className="formRadioGroupBox">
                                                        <input type="radio" id='experience' name="workStatus" checked={workStatus === 'experience'} 
                                                            onChange={handleWorkStatusChange} />
                                                        <label htmlFor="experience">
                                                            <div className="radioIcon"></div>
                                                            <div className="radioText">Experience</div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {workStatus === 'experience' && (
                                                <div className="formGroup col-1 experienceArea">
                                                    <label htmlFor="">Total experience</label>
                                                    <div className="formGroupDorpdownList">
                                                        <div className="formGroupDorpdownListBox">
                                                            <Select
                                                                options={year}
                                                                placeholder="Number of Year"
                                                                isSearchable={true}
                                                                className="customSelect"
                                                                isClearable={isClearable}
                                                                styles={customStyles}
                                                                name="totalexperince"
                                                                onChange={handletotalexperiance}
                                                            />
                                                            {errors.totalexperince && <p className="error">{errors.totalexperince}</p>}
                                                        </div>
                                                        
                                                        <div className="formGroupDorpdownListBox">
                                                            <Select
                                                                options={month}
                                                                placeholder="Number of Month"
                                                                isSearchable={true}
                                                                className="customSelect"
                                                                isClearable={isClearable}
                                                                styles={customStyles}
                                                                name="numbermonth"
                                                                onChange={handlenumbermonth}
                                                            />
                                                            {errors.numbermonth && <p className="error">{errors.numbermonth}</p>}
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            )}
                                            <div className="formGroup col-1">
                                                <label htmlFor="">Current location</label>
                                                <div className="formGroupDorpdownList">
                                                    <div className="formGroupDorpdownListBox">
                                                        <Select
                                                            options={locations}
                                                            placeholder="Select Country"
                                                            isSearchable={true}
                                                            className="customSelect"
                                                            isClearable={isClearable}
                                                            styles={customStyles}
                                                            name="counrtryselect"
                                                            onChange={handlecounrtyselect}
                                                        />
                                                        {errors.counrtryselect && <p className="error">{errors.counrtryselect}</p>}
                                                    </div>
                                                    
                                                    <div className="formGroupDorpdownListBox">
                                                        <Select
                                                            options={place}
                                                            placeholder="Select Place"
                                                            isSearchable={true}
                                                            className="customSelect"
                                                            isClearable={isClearable}
                                                            styles={customStyles}
                                                            name="placeselect"
                                                            onChange={handleplaceselect}
                                                        />
                                                        {errors.placeselect && <p className="error">{errors.placeselect}</p>}
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div className="formGroup col-1">
                                                <label htmlFor="">Profile Summary</label>
                                                <textarea name="profilesummary" onKeyUp={handleChangev1} onChange={handleChangev1}></textarea>
                                                {errors.profilesummary && <p className="error">{errors.profilesummary}</p>}
                                            </div>
                                                <div className="applyJobFormListBoxHeadFooter">
                                            {/* <div className="nextFormBtn" onClick={handleNextClick}>Submit Profile & Continue <PiCaretRightBold /></div> */}
                                            <button className="nextFormBtn" disabled={isLoading}>Submit Profile & Continue </button>
                                            {/* {isLoading ? <Loader /> : 'Save'} */}
                                                </div>
                                        </form>
                                    </div>
                                    
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <div className="applyJobFormListBox">
                                    <div className="applyJobFormListBoxHead">
                                        <h2 className="subTitle">Upload Resume</h2>
                                    </div>
                                    <div className="applyJobFormListBoxHeadBody">
                                        <form action="">
                                            <UploadResume onHandleImageUpload={handleImageUpload}/>
                                            {pdfError && <p className="error">{pdfError}</p>}
                                        </form>
                                    </div>
                                    <div className="applyJobFormListBoxHeadFooter">
                                        <div className="prevFormBtn" onClick={handlePrevClick}><PiCaretLeftBold /> Back</div>
                                        <div className="nextFormBtn" onClick={handleResume}>Upload Resume & Continue <PiCaretRightBold /></div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <div className="applyJobFormListBox">
                                    <div className="applyJobFormListBoxHead">
                                        <h2 className="subTitle">Education Details</h2>
                                    </div>
                                    <div className="applyJobFormListBoxHeadBody">
                                        <form action="">
                                            <EducationForm />
                                        </form>
                                    </div>
                                    <div className="applyJobFormListBoxHeadFooter">
                                        <div className="prevFormBtn" onClick={handlePrevClick}><PiCaretLeftBold /> Back</div>
                                        <div className="nextFormBtn" onClick={handleNextClick}>Submit Education & Continue <PiCaretRightBold /></div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <div className="applyJobFormListBox">
                                    <div className="applyJobFormListBoxHead">
                                        <h2 className="subTitle">Personal Details</h2>
                                    </div>
                                    <div className="applyJobFormListBoxHeadBody">
                                        <form action="">
                                            <PersonalDetailsForm formDataperson={formDataperson} setFormDataperson={setFormDataperson}/>
                                        </form>
                                    </div>
                                    <div className="applyJobFormListBoxHeadFooter">
                                        <div className="prevFormBtn" onClick={handlePrevClick}><PiCaretLeftBold /> Back</div>
                                        <div className="nextFormBtn" onClick={handlepersondetails}>Submit Personal Details & Continue <PiCaretRightBold /></div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <div className="applyJobFormListBox">
                                    <div className="applyJobFormListBoxHead">
                                        <h2 className="subTitle">Review & Apply</h2>
                                    </div>
                                    <div className="applyJobFormListBoxHeadBody">
                                        <ReviewForm setValue={setValue} />
                                    </div>
                                    <div className="applyJobFormListBoxHeadFooter">
                                        <div className="prevFormBtn" onClick={handlePrevClick}><PiCaretLeftBold /> Back</div>
                                        <div className="nextFormBtn" onClick={handleNextClick}>Apply Now <PiCaretRightBold /></div>
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </section>
            <HomeContact />
        </>
    );
}


export default ApplyJobForm;
