import HomeBanner from "./homeBanner";
import HomeExploreJobs from "./homeExploreJobs";
import HomeAbout from "./homeAbout";
import HomeJobCatagory from "./homeJobCatagory";
import HomeService from "./homeService";
import HomeBlog from "./homeBlog";
import HomeTestimonials from "./homeTestimonials";
import HomeGallery from "./homeGallery";
import HomeContact from "./homeContact";

function Home() {
    
  return (
    <>
        <HomeBanner />
        <HomeExploreJobs />
        <HomeAbout />
        <HomeJobCatagory />
        <HomeService />
        <HomeBlog />
        <HomeTestimonials />
        <HomeGallery />
        <HomeContact />
    </>
  );
}

export default Home;
